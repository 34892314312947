import root from 'utilities/root.js';
import Wistia from 'wistia_namespace.js';
import { Url } from 'utilities/url.js';
import { findScriptInDomBySrc } from 'utilities/script-utils.js';
import { runScript } from 'utilities/runScript.js';
import { poll } from 'utilities/core.js';
import { wlog } from 'utilities/wlog.js';

const logger = wlog.getPrefixedFunctions('jsonp');

if (Wistia._jsonpCounter == null) {
  Wistia._jsonpCounter = 0;
}

// lightweight JSONP library from
//   http://www.nonobtrusive.com/2010/05/20/lightweight-jsonp-without-any-3rd-party-libraries/
export function jsonp(url, params, callback, options) {
  let query = '?';
  var jsonp;
  params = params || {};
  for (let key in params) {
    if (key != 'callback' && params.hasOwnProperty(key) && params[key] != null) {
      query += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}&`;
    }
  }
  logger.info(url, query, options);

  var matchUrl;
  var scriptAlreadyInDom;
  if (options && options.once) {
    matchUrl = (url + query).replace(/\.json(?!p)/, '.jsonp');
    // remove trailing `&` from the match url
    matchUrl = matchUrl.replace(/&$/, '');
    scriptAlreadyInDom = findScriptInDomBySrc(matchUrl, {
      ignoreProtocol: true,
      scriptRegex: options.scriptRegex,
    });
  }

  if (scriptAlreadyInDom) {
    // if the script is already in the DOM, let's just poll until data for
    // it is set.
    var parsed = new Url(matchUrl);
    var cacheKey = `wistiajsonp-${parsed.relative()}`;
    logger.info('script already in DOM', matchUrl, 'polling', cacheKey);
    poll(
      () => {
        logger.info('found', cacheKey, 'on root object');
        return root[cacheKey] != null;
      },
      () => {
        callback(root[cacheKey]);
      },
      15,
      30000,
    );
  } else {
    if (params && params.callback) {
      jsonp = params.callback;
    } else {
      jsonp = `wistiajson${++Wistia._jsonpCounter}`;
    }
    logger.info('defining on root:', jsonp);
    root[jsonp] = function (data) {
      callback(data);
      delete root[jsonp];
      root[jsonp] = null;
    };
    const scriptSrc = `${url + query}callback=${jsonp}`;
    logger.info('runScript', scriptSrc);
    runScript(scriptSrc).catch((e) => {
      console.error(e);
    });
  }
  return jsonp;
}

// jsonp fetching with error func and timeout
export const jsonpPlus = (url, params, callback, options = {}) => {
  if (!options.timeout) {
    options.timeout = 5000;
  }
  if (!options.onerror) {
    options.onerror = () => {};
  }

  const errorTimeout = setTimeout(options.onerror, options.timeout);
  const onSuccess = (data) => {
    clearTimeout(errorTimeout);
    if (callback) {
      callback(data);
    }
  };

  jsonp(url, params, onSuccess, options);
};

import { h, render } from 'preact';

class Control {
  #disabledButton;

  constructor(video) {
    this.video = video;
    this.unbinds = [];
    this.reactMounts = {};
  }

  mount(rootElem) {
    this.rootElem = rootElem;
  }

  // The `disabledButton` is used in the UIBehavior and fed into the
  // ControlBarButtonWithDialog to disable control bar buttons
  set disabledButton(state) {
    this.#disabledButton = state;
  }

  get disabledButton() {
    return this.#disabledButton;
  }
}

export default Control;

(function(W) {
  return W._initializers.initSpecificTime = function() {
    var e, foundAVideo, hashedId, ref, time;
    try {
      if (parent !== self) {
        return;
      }
      time = location.href.match(/[?&]wtime=([\dhms]*)/);
      hashedId = (ref = location.href.match(/[?&]wvideo(id)?=([^&]+)/)) != null ? ref[2] : void 0;
      if (!((time != null ? time.length : void 0) >= 2)) {
        return;
      }
      if (W._specificTimeInitiated) {
        return;
      }
      W._specificTimeInitiated = true;
      foundAVideo = false;
      return wistiaEmbeds.onFind(function(video) {
        if (hashedId && video.hashedId() !== hashedId) {
          return;
        }
        if (!foundAVideo) {
          foundAVideo = true;
          video.embedded(function() {
            var ref1;
            return (ref1 = video.popover) != null ? ref1.show() : void 0;
          });
          return video.time(time[1]).play();
        }
      });
    } catch (error) {
      e = error;
      W.error(e);
      return W._specificTimeInitiated = false;
    }
  };
})(Wistia);

import assign from 'utilities/assign.js';

const DONE = 4;

export const xhr = (type, url, data, headers = {}, options = {}) => {
  return new Promise((resolve, reject) => {
    const XhrClass = options.XMLHttpRequest || XMLHttpRequest;
    const xhr = new XhrClass();
    if (options.afterConstructor) {
      options.afterConstructor(xhr);
    }

    const onReadyStateChange = () => {
      if (xhr.readyState < DONE) {
        return;
      }

      // 200-300 = success, 304 = Not Modified
      if (xhr.status != null && ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304)) {
        if (xhr.response && typeof xhr.response !== 'string') {
          // The browser has modified the response to a non-String type; handle
          // it downstream.
          resolve({ data: xhr.response, status: xhr.status, statusText: xhr.statusText, xhr });
        } else if (options.dataType === 'json') {
          // We're getting a string response but have asked for dataType='json';
          // parse it ourselves.
          try {
            const responseJson = JSON.parse(xhr.responseText);
            resolve({
              data: responseJson,
              status: xhr.status,
              statusText: xhr.statusText,
              xhr,
            });
          } catch (e) {
            const badParseError = new Error(
              `${type} ${url} - Error parsing response text: ${xhr.responseText}.`,
            );
            console.error(badParseError.message);
            reject(badParseError);
          }
        } else {
          // This is a simple non-JSON string response.
          resolve({
            data: xhr.responseText,
            status: xhr.status,
            statusText: xhr.statusText,
            xhr,
          });
        }
      } else {
        const badStatusError = new Error(
          `${type} ${url} - Got an unsuccessful status code: ${xhr.status}. ${xhr.statusText}`,
        );
        badStatusError.status = xhr.status;
        console.error(badStatusError.message);
        reject(badStatusError);
      }
    };
    try {
      xhr.addEventListener('readystatechange', onReadyStateChange, false);
    } catch (e) {
      console.error(e.message);
      console.error(e.stack);
    }

    const onError = () => {
      const error = new Error('XHR error');
      error.status = xhr.status;
      error.xhr = xhr;
      reject(error);
    };
    xhr.addEventListener('error', onError, false);

    const onTimeout = (e) => {
      const error = new Error('XHR timeout');
      error.status = xhr.status;
      error.message = e && e.message;
      error.xhr = xhr;
      reject(e);
    };
    xhr.addEventListener('timeout', onTimeout, false);

    xhr.open(type, url, true);

    if (options.withCredentials) {
      xhr.withCredentials = true;
    }

    if (options.timeout) {
      xhr.timeout = options.timeout;
    }

    if (options.dataType != null) {
      xhr.responseType = options.dataType;
    }

    if (type === 'POST' && !headers['content-type']) {
      xhr.setRequestHeader('content-type', 'application/x-www-form-urlencoded');
    }

    if (headers) {
      for (let name in headers) {
        if (headers.hasOwnProperty(name)) {
          xhr.setRequestHeader(name.toLowerCase(), headers[name]);
        }
      }
    }

    xhr.send(data);

    if (options.afterSend) {
      options.afterSend(xhr);
    }
  });
};

export const xhrGet = (url, headers, options) => {
  return xhr('GET', url, null, headers, options);
};

export const xhrPost = (url, data, headers, options) => {
  return xhr('POST', url, data, headers, options);
};

export const xhrJsonPost = (url, data, headers, options) => {
  headers = assign({}, headers, {
    'content-type': 'application/json',
  });
  return xhr('POST', url, JSON.stringify(data), headers, options);
};

var Interpolation;

Interpolation = (function() {
  function Interpolation(arg) {
    var easing, outputEnd, outputStart, ref, ref1, ref2, seedFunction, seedRange, seedStart;
    seedRange = arg.seedRange, seedFunction = arg.seedFunction, seedStart = arg.seedStart, outputStart = (ref = arg.outputStart) != null ? ref : 0, outputEnd = (ref1 = arg.outputEnd) != null ? ref1 : 1, easing = (ref2 = arg.easing) != null ? ref2 : Interpolation.linear;
    if (seedRange == null) {
      throw new Error('Must provide seedRange argument');
    }
    if ((seedFunction != null) && typeof seedFunction !== 'function') {
      throw new Error('Given seed is not a function');
    }
    if (typeof easing !== 'function') {
      throw new Error("Invalid easing function given: " + this.easing);
    }
    this._seedRange = seedRange;
    this._seedFunction = seedFunction || function() {
      return new Date().getTime();
    };
    this._outputStart = outputStart;
    this._outputEnd = outputEnd;
    this._easing = easing;
    this._seedStart = (typeof seedStart === "function" ? seedStart() : void 0) || this.seed();
  }

  Interpolation.prototype.seed = function() {
    return this.seedFunction()();
  };

  Interpolation.prototype.seedStart = function() {
    return this._seedStart;
  };

  Interpolation.prototype.seedRange = function() {
    return this._valOrFn(this._seedRange);
  };

  Interpolation.prototype.seedFunction = function() {
    return this._seedFunction;
  };

  Interpolation.prototype.outputStart = function() {
    return this._valOrFn(this._outputStart);
  };

  Interpolation.prototype.outputEnd = function() {
    return this._valOrFn(this._outputEnd);
  };

  Interpolation.prototype.easing = function() {
    return this._valOrFn(this._easing);
  };

  Interpolation.prototype.value = function() {
    return this._easing.apply(this, this.easingArgs());
  };

  Interpolation.prototype.atEnd = function() {
    return this.ratio() === 1;
  };

  Interpolation.prototype.atStart = function() {
    return this.ratio() === 0;
  };

  Interpolation.prototype.easingArgs = function() {
    return [this.c(), this.t(), this.d(), this.b()];
  };

  Interpolation.prototype.seedDelta = function() {
    if (this.seedRange() > 0) {
      return Math.min(this.seedRange(), this.seed() - this.seedStart());
    } else if (this.seedRange() < 0) {
      return Math.max(this.seedRange(), this.seed() - this.seedStart());
    } else {
      return 0;
    }
  };

  Interpolation.prototype.ratio = function() {
    var ratioChange, seedRange;
    seedRange = this.seedRange();
    if (seedRange === 0) {
      return 1;
    }
    return ratioChange = Math.max(0, Math.min(1, this.seedDelta() / seedRange));
  };

  Interpolation.prototype.c = function() {
    return this.outputEnd() - this.outputStart();
  };

  Interpolation.prototype.t = function() {
    return this.seedDelta();
  };

  Interpolation.prototype.d = function() {
    return this.seedRange();
  };

  Interpolation.prototype.b = function() {
    return this.outputStart();
  };

  Interpolation.prototype._valOrFn = function(val) {
    if (typeof val === 'function') {
      return val();
    } else {
      return val;
    }
  };

  Interpolation.linear = function(c, t, d, b) {
    return c * t / (d || 1) + b;
  };

  Interpolation.easeInOut = function(c, t, d, b) {
    t /= (d || 1) / 2;
    if (t < 1) {
      return c / 2 * t * t + b;
    } else {
      t -= 1;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }
  };

  return Interpolation;

})();

module.exports = Interpolation;

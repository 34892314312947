import root from 'utilities/root.js';

if (root.Wistia == null) {
  root.Wistia = {};
}

const W = root.Wistia;

if (W._initializers == null) {
  W._initializers = {};
}

if (W._destructors == null) {
  W._destructors = {};
}

if (W.mixin == null) {
  W.mixin = (klass, obj) => {
    for (let k in obj) {
      if (obj.hasOwnProperty(k)) {
        klass[k] = obj[k];
      }
    }
  };
}

export default root.Wistia;

(function(W) {
  var lsModule;
  if (W.localStorage) {
    return;
  }
  lsModule = require('utilities/legacyLocalstorage.js');
  W.localStorage = lsModule.setOrGet;
  W.removeLocalStorage = lsModule.removeLocalStorage;
  W.dumpLocalStorage = lsModule.dumpLocalStorage;
  W._initializers.initLocalStorage = function() {
    return lsModule.init();
  };
  return W._destructors.destroyLocalStorage = function() {
    return lsModule.uncache();
  };
})(Wistia);

(function(W) {
  var Assets, Hosts, MediaData, PlaylistData, ScriptUtils, appHostname, jsonp, jsonpPlus, ref;
  if (W.remote) {
    return;
  }
  appHostname = require('_app_hostname.js')["default"];
  ScriptUtils = require('utilities/script-utils.js');
  Assets = require('utilities/assets.js');
  Hosts = W.Hosts = require('utilities/hosts.js');
  MediaData = W.MediaData = require('utilities/media_data.js');
  PlaylistData = W.PlaylistData = require('utilities/playlist_data.js');
  ref = require('utilities/jsonp.js'), jsonp = ref.jsonp, jsonpPlus = ref.jsonpPlus;
  W.remote = {};
  W.remote.appHostname = appHostname;
  W.remote.embedHost = Hosts.cdnFastWistiaComHost;
  W.remote.externalEmbedHost = Hosts.cdnFastWistiaNetHost;
  W.remote.appHost = Hosts.appHost;
  W.remote.assetHost = Hosts.deliveryHost;
  W.remote.assetUrlWithCorrectHost = function(media, assetUrl, proto) {
    return Assets.assetUrlWithCorrectHost(assetUrl, proto);
  };
  W.remote.media = MediaData.fetchMedia;
  W.cacheMedia = MediaData.cacheMedia;
  W.cacheAbTest = MediaData.cacheAbTest;
  W.uncacheMedia = MediaData.uncacheMedia;
  W.uncacheAbtest = MediaData.uncacheAbTest;
  W.mediaFromCache = MediaData.mediaFromCache;
  W.abTestFromCache = MediaData.abTestFromCache;
  W.remote.playlist = PlaylistData.fetchPlaylist;
  W.remote.fetch = jsonpPlus;
  return W.jsonp = {
    get: jsonp
  };
})(Wistia);

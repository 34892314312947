(function(W) {
  var addInlineCss, addTrackingForGrid, countMetric, elemAppend, elemFromObject, elemHeight, elemRemove, elemStyle, elemWidth, generateHtml, getPluginsNotInList, pluginScriptsToLoad, ref, ref1, seqId, trackGridAppend;
  ref = require('utilities/elem.js'), addInlineCss = ref.addInlineCss, elemAppend = ref.elemAppend, elemFromObject = ref.elemFromObject, elemHeight = ref.elemHeight, elemRemove = ref.elemRemove, elemStyle = ref.elemStyle, elemWidth = ref.elemWidth;
  countMetric = require('utilities/simpleMetrics.js').countMetric;
  seqId = require('utilities/seqid.js').seqId;
  ref1 = require('utilities/pluginScriptsToLoad.js'), pluginScriptsToLoad = ref1.pluginScriptsToLoad, getPluginsNotInList = ref1.getPluginsNotInList;
  generateHtml = require('utilities/generate.js').generateHtml;
  if (W.gridify) {
    return;
  }
  W.gridifyCss = function(gridId) {
    var containerId;
    containerId = gridId.replace(/_grid$/, '');
    return "#" + gridId + "_wrapper{-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;font-family:Arial,sans-serif;font-size:14px;height:100%;position:relative;text-align:left;width:100%;}\n#" + gridId + "_wrapper *{-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}\n#" + gridId + "_above{position:relative;}\n#" + gridId + "_main{display:block;height:100%;position:relative;}\n#" + gridId + "_behind{height:100%;left:0;position:absolute;top:0;width:100%;}\n#" + gridId + "_center{height:100%;overflow:hidden;position:relative;width:100%;}\n#" + gridId + "_front{display:none;height:100%;left:0;position:absolute;top:0;width:100%;}\n#" + gridId + "_top_inside{position:absolute;left:0;top:0;width:100%;}\n#" + gridId + "_top{width:100%;position:absolute;bottom:0;left:0;}\n#" + gridId + "_bottom_inside{position:absolute;left:0;bottom:0;width:100%;}\n#" + gridId + "_bottom{width:100%;position:absolute;top:0;left:0;}\n#" + gridId + "_left_inside{height:100%;position:absolute;left:0;top:0;}\n#" + gridId + "_left{height:100%;position:absolute;right:0;top:0;}\n#" + gridId + "_right_inside{height:100%;right:0;position:absolute;top:0;}\n#" + gridId + "_right{height:100%;left:0;position:absolute;top:0;}\n#" + gridId + "_below{position:relative;}";
  };
  W.gridifyHtml = function(gridId) {
    return {
      id: gridId + "_wrapper",
      childNodes: [
        {
          id: gridId + "_above"
        }, {
          id: gridId + "_main",
          childNodes: [
            {
              id: gridId + "_behind"
            }, {
              id: gridId + "_center"
            }, {
              id: gridId + "_front"
            }, {
              id: gridId + "_top_inside",
              childNodes: {
                id: gridId + "_top"
              }
            }, {
              id: gridId + "_bottom_inside",
              childNodes: {
                id: gridId + "_bottom"
              }
            }, {
              id: gridId + "_left_inside",
              childNodes: {
                id: gridId + "_left"
              }
            }, {
              id: gridId + "_right_inside",
              childNodes: {
                id: gridId + "_right"
              }
            }
          ]
        }, {
          id: gridId + "_below"
        }
      ]
    };
  };
  W.createGrid = function(video, options) {
    var grid, node;
    grid = W.createGridSkeleton();
    W.initGridEventListeners(grid);
    W.initGridDimensions(video, grid, options);
    for (node in grid) {
      if (grid[node].nodeName !== 'STYLE' && video._opts._inLegacyPlaylist !== true) {
        addTrackingForGrid(grid[node], video);
      }
    }
    return grid;
  };
  addTrackingForGrid = (function(_this) {
    return function(node, video) {
      node.originalAppendChild = node.appendChild;
      node.originalAppend = node.append;
      node.originalPrepend = node.prepend;
      node.appendChild = function(val, opts) {
        if (opts == null) {
          opts = {};
        }
        if (opts.wistiaGridCaller !== true && W._hasTrackedGrid !== true) {
          W._hasTrackedGrid = true;
          trackGridAppend(video);
        }
        return node.originalAppendChild(val);
      };
      node.append = function(val, opts) {
        if (opts == null) {
          opts = {};
        }
        if (opts.wistiaGridCaller !== true && W._hasTrackedGrid !== true) {
          W._hasTrackedGrid = true;
          trackGridAppend(video);
        }
        return node.originalAppend(val);
      };
      return node.prepend = function(val, opts) {
        if (opts == null) {
          opts = {};
        }
        if (opts.wistiaGridCaller !== true && W._hasTrackedGrid !== true) {
          W._hasTrackedGrid = true;
          trackGridAppend(video);
        }
        return node.originalPrepend(val);
      };
    };
  })(this);
  trackGridAppend = function(video) {
    var pluginScripts;
    pluginScripts = pluginScriptsToLoad(video._mediaData, video._opts);
    return countMetric('custom-plugin-grid-append', 1, {
      hashedId: video._mediaData.hashedId,
      href: window.location.href,
      plugins: getPluginsNotInList(pluginScripts)
    });
  };
  W.createGridSkeleton = function() {
    var gridCss, gridId, gridRoot, i, len, result, sector, sectors;
    gridId = seqId('wistia_grid_');
    gridRoot = elemFromObject(W.gridifyHtml(gridId));
    gridCss = addInlineCss(gridRoot, W.gridifyCss(gridId));
    result = {};
    result.css = gridCss;
    result.root = gridRoot;
    elemStyle(gridRoot, {
      display: 'none'
    });
    elemAppend(document.body, gridRoot);
    sectors = ['wrapper', 'main', 'above', 'below', 'top', 'right', 'bottom', 'left', 'top_inside', 'right_inside', 'bottom_inside', 'left_inside', 'front', 'center', 'behind'];
    for (i = 0, len = sectors.length; i < len; i++) {
      sector = sectors[i];
      result[sector] = document.getElementById(gridId + "_" + sector);
    }
    elemRemove(gridRoot);
    elemStyle(gridRoot, {
      display: 'block'
    });
    return result;
  };
  W.initGridEventListeners = function(grid) {
    return grid.center.addEventListener("scroll", function() {
      return grid.center.scrollTop = 0;
    });
  };
  W.initGridDimensions = function(video, grid, extra) {
    var chrome, container, options;
    if (extra == null) {
      extra = {};
    }
    if (extra.initDimensions === false) {
      return;
    }
    container = video._embedContainer;
    chrome = video.chrome;
    options = video._opts;
    chrome.style.height = (elemHeight(container)) + "px";
    return grid.main.style.width = (elemWidth(container)) + "px";
  };
  W.gridify = function(options, container) {
    var gridId, i, len, ref2, result, sector;
    result = {};
    gridId = container.id + "_grid";
    container.innerHTML = generateHtml(W.gridifyHtml(gridId));
    ref2 = ['wrapper', 'main', 'above', 'below', 'top', 'right', 'bottom', 'left', 'top_inside', 'right_inside', 'bottom_inside', 'left_inside', 'front', 'center', 'behind'];
    for (i = 0, len = ref2.length; i < len; i++) {
      sector = ref2[i];
      result[sector] = document.getElementById(gridId + "_" + sector);
    }
    addInlineCss(result.wrapper, W.gridifyCss(gridId));
    result.wrapper.style.height = (elemHeight(container)) + "px";
    result.main.style.width = (elemWidth(container)) + "px";
    return result;
  };
  W.isGridElem = function(grid, elem) {
    var sectionElem, sectionName;
    for (sectionName in grid) {
      sectionElem = grid[sectionName];
      if (elem === sectionElem) {
        return true;
      }
    }
    return false;
  };
  W.grid = {};
  W.grid.allNodesHidden = function(nodes) {
    var i, len, node;
    if (nodes.length === 0) {
      return true;
    }
    for (i = 0, len = nodes.length; i < len; i++) {
      node = nodes[i];
      if (node.style && node.style.display !== 'none') {
        return false;
      }
    }
    return true;
  };
  W.grid.zeroEmptySections = function(video) {
    var elem, i, len, ref2, results, section;
    ref2 = ['top', 'bottom', 'left', 'right', 'above', 'below'];
    results = [];
    for (i = 0, len = ref2.length; i < len; i++) {
      section = ref2[i];
      elem = video.grid[section];
      if (!elem.childNodes.length) {
        elem.appendChild(document.createTextNode(' '), {
          wistiaGridCaller: true
        });
        elem.style.height = '0px';
        elem.style.fontSize = '0px';
        elem.style.lineHeight = '0px';
        results.push(elem.isEmpty = true);
      } else if (W.grid.allNodesHidden(elem.childNodes)) {
        elem.style.height = '0px';
        elem.style.fontSize = '0px';
        elem.style.lineHeight = '0px';
        results.push(elem.isEmpty = true);
      } else {
        elem.style.height = '';
        elem.style.fontSize = '';
        results.push(elem.style.lineHeight = '');
      }
    }
    return results;
  };
  W.grid.wrapperHeight = function(video) {
    var wrapper, wrapperHeight;
    wrapper = video.grid.wrapper;
    wrapperHeight = elemHeight(wrapper);
    return wrapperHeight;
  };
  W.grid.wrapperWidth = function(video) {
    var wrapper, wrapperWidth;
    wrapper = video.grid.wrapper;
    wrapperWidth = elemWidth(wrapper);
    return wrapperWidth;
  };
  W.grid.fitVertical = function(video) {
    var bh, grid, main, newHeight, th, wrapper;
    if (video._opts.dontFit) {
      return;
    }
    W.grid.zeroEmptySections(video);
    grid = video.grid;
    main = grid.main;
    wrapper = grid.wrapper;
    th = Math.max(elemHeight(grid.above), elemHeight(grid.top));
    bh = Math.max(elemHeight(grid.below), elemHeight(grid.bottom));
    newHeight = Math.max(0, W.grid.wrapperHeight(video) - th - bh);
    main.style.height = newHeight + "px";
    if (elemWidth(grid.left) === 0) {
      main.style.left = "0px";
    }
    main.style.marginTop = (elemHeight(grid.top)) + "px";
  };
  W.grid.fitHorizontal = function(video) {
    var grid, lw, main, newWidth, rw, wrapper;
    if (video._opts.dontFit) {
      return;
    }
    W.grid.zeroEmptySections(video);
    grid = video.grid;
    wrapper = grid.wrapper;
    main = grid.main;
    lw = elemWidth(grid.left);
    rw = elemWidth(grid.right);
    newWidth = W.grid.wrapperWidth(video) - lw - rw;
    main.style.width = newWidth + "px";
    main.style.left = lw + "px";
  };
  W.grid.fit = function(video, position) {
    if (/left|right/.test(position)) {
      W.grid.fitHorizontal(video);
    } else if (/top|bottom|above|below/.test(position)) {
      W.grid.fitVertical(video);
    }
    video.ieSizeHack();
  };
})(Wistia);

import { secondsConverter } from './duration.js';

export const iso8601TimeToSeconds = (val) => {
  const stringVal = val.toString() || '';

  let seconds = 0;
  const multipliers = [1, 60, 3600];
  const addends = stringVal.split(':').reverse();

  addends.forEach((addend, i) => {
    seconds += addend * multipliers[i];
  });

  return Math.abs(parseInt(seconds)) | 0;
};

// Crude ISO 8601 validation
export const isValidIso8601 = (val) => {
  if (!val || typeof val !== 'string') {
    return false;
  }

  const trimRegex = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
  const trimmedVal = val.replace(trimRegex, '');

  if (trimmedVal.match(/[\d:]+/) === null) {
    return false;
  }

  if (trimmedVal.match(/[\d:]+/)[0] !== trimmedVal) {
    return false;
  }

  return true;
};

export const secondsToIso8601Date = (seconds) => {
  const createdAt = new Date(seconds * 1000);
  const year = createdAt.getFullYear();
  let month = createdAt.getMonth() + 1;
  let day = createdAt.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

export const secondsToIso8601Duration = (totalSeconds) => {
  const { hours, minutes, seconds } = secondsConverter(totalSeconds, 'hms');

  let result = '';

  if (hours) {
    result += `${hours}H`;
  }

  if (minutes || (totalSeconds > 60 && seconds !== 0)) {
    result += `${minutes}M`;
  }

  if (seconds || totalSeconds === 0) {
    result += `${seconds}S`;
  }

  return result;
};

export const secondsToIso8601Time = (totalSeconds) => {
  const { hours, minutes, seconds } = secondsConverter(totalSeconds, 'hms');

  const h = hours.toString();
  const m = minutes.toString();
  const s = seconds.toString();

  const time = [
    '00'.substring(h.length) + h,
    '00'.substring(m.length) + m,
    '00'.substring(s.length) + s,
  ];

  return time.join(':');
};

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

(function(W) {
  var doTimeout, seqId, wData;
  wData = require('utilities/wistiaData.js').wData;
  doTimeout = require('utilities/timeout-utils.js').doTimeout;
  seqId = require('utilities/seqid.js').seqId;
  if (W.EmbedListener) {
    return;
  }
  W.EmbedListener = (function(superClass) {
    extend(EmbedListener, superClass);

    function EmbedListener() {
      this.uuid = seqId();
      this.bindHandles();
      this.bind('down', (function(_this) {
        return function() {
          _this.info('got down event, prune and rebind handles');
          return doTimeout('prune_zombies_later', function() {
            _this.pruneZombies();
            return _this.bindHandles();
          });
        };
      })(this));
      this.bind('up', (function(_this) {
        return function(video) {
          _this.info('got up event, set _bound=false for', video);
          video._bound = false;
          return _this.bindHandles();
        };
      })(this));
    }

    EmbedListener.prototype.bindHandles = function() {
      var allUnboundHandles, handle, j, len, results;
      allUnboundHandles = this.allUnboundHandles();
      this.info('bindHandles', allUnboundHandles);
      results = [];
      for (j = 0, len = allUnboundHandles.length; j < len; j++) {
        handle = allUnboundHandles[j];
        this.info('bind to all events for', handle);
        handle._bound = true;
        results.push(this.push(handle));
      }
      return results;
    };

    EmbedListener.prototype.pruneZombies = function() {
      var handle, i, j, k, l, len, len1, ref, ref1, results, saved;
      this.info('pruneZombies');
      saved = [];
      ref = this.allBoundHandles();
      for (j = 0, len = ref.length; j < len; j++) {
        handle = ref[j];
        if (handle.looksDown()) {
          this.info('prune zombie', handle);
        } else {
          saved.push(handle);
        }
      }
      for (i = k = 0, ref1 = this.length; 0 <= ref1 ? k <= ref1 : k >= ref1; i = 0 <= ref1 ? ++k : --k) {
        delete this[i];
      }
      this.length = 0;
      results = [];
      for (l = 0, len1 = saved.length; l < len1; l++) {
        handle = saved[l];
        results.push(this.push(handle));
      }
      return results;
    };

    EmbedListener.prototype.allBoundHandles = function() {
      var handle, j, len, ref, result;
      result = [];
      ref = this.allHandles();
      for (j = 0, len = ref.length; j < len; j++) {
        handle = ref[j];
        if (handle._bound) {
          result.push(handle);
        }
      }
      return result;
    };

    EmbedListener.prototype.allUnboundHandles = function() {
      var handle, j, len, ref, result;
      result = [];
      ref = this.allHandles();
      for (j = 0, len = ref.length; j < len; j++) {
        handle = ref[j];
        if (!handle._bound) {
          result.push(handle);
        }
      }
      return result;
    };

    EmbedListener.prototype.allHandles = function() {
      return this.apiHandles().concat(this.iframeApiHandles());
    };

    EmbedListener.prototype.iframeApiHandles = function() {
      var iframe, j, len, ref, result;
      if (!this.iframes) {
        this.iframes = document.getElementsByTagName("iframe");
      }
      result = [];
      ref = this.iframes;
      for (j = 0, len = ref.length; j < len; j++) {
        iframe = ref[j];
        if (iframe.wistiaApi && !(iframe.wistiaApi instanceof W.IframeApiProxy) && iframe.wistiaApi !== 'removed') {
          result.push(iframe.wistiaApi);
        }
      }
      return result;
    };

    EmbedListener.prototype.apiHandles = function() {
      var handle, result, uuid, vidHash;
      result = [];
      if (window.Wistia) {
        vidHash = wData("video");
        for (uuid in vidHash) {
          handle = vidHash[uuid];
          result.push(handle);
        }
      }
      return result;
    };

    EmbedListener.prototype.each = function(fn) {
      var handle, j, len, ref, results;
      ref = this.allBoundHandles();
      results = [];
      for (j = 0, len = ref.length; j < len; j++) {
        handle = ref[j];
        results.push(fn(handle));
      }
      return results;
    };

    EmbedListener.prototype.onFind = function(fn) {
      var runWhenUp;
      runWhenUp = (function(_this) {
        return function(video) {
          return video.up(function() {
            _this.info('onFind', video);
            fn(video);
            return video.unbind;
          });
        };
      })(this);
      this.each(runWhenUp);
      return this.bind('initembed', runWhenUp);
    };

    return EmbedListener;

  })(Array);
  W.mixin(W.EmbedListener.prototype, W.bindable);
  W.mixin(W.EmbedListener.prototype, W.logHelpers);
  W._initializers.initEmbedShepherd = function() {
    if (window.wistiaEmbeds) {
      W.info('window.wistiaEmbeds already exists, call bindHandles');
      if (typeof wistiaEmbeds.bindHandles === "function") {
        wistiaEmbeds.bindHandles();
      }
    } else {
      W.info('initialize embed shepherd');
      window.wistiaEmbeds = new W.EmbedListener();
    }
    W.info('call window.wistiaEmbedShepherdReady()');
    return typeof window.wistiaEmbedShepherdReady === "function" ? window.wistiaEmbedShepherdReady() : void 0;
  };
  return W._destructors.destroyEmbedShepherd = function() {
    var ref;
    if ((ref = window.wistiaEmbeds) != null) {
      ref.each(function(video) {
        return video._bound = false;
      });
    }
    return window.wistiaEmbeds = null;
  };
})(Wistia);

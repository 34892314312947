import appHostname from '_app_hostname';
import root from 'utilities/root.js';
import { Url, proto as urlProto } from 'utilities/url.js';

const APP_WISTIA_COM = appHostname('app');
const CDN_FAST_WISTIA_COM = appHostname('fast');
const CDN_FAST_WISTIA_NET = __FAST_HOSTNAME__;
const PROD_CDN_FAST_WISTIA_NET = __PROD_FAST_HOSTNAME_NET__;
const PROD_CDN_FAST_WISTIA_COM = __PROD_FAST_HOSTNAME_COM__;

const DELIVERY_HOST = __EMBED_HOST__;
const DELIVERY_SSL_HOST = __SSL_EMBED_HOST__;
const AKAMAI_HOST = __AKAMAI_HOST__;
const PROD_AKAMAI_HOST = __PROD_AKAMAI_HOST__;
const AKAMAI_SSL_HOST = __AKAMAI_SSL_HOST__;
const PROD_AKAMAI_SSL_HOST = __PROD_AKAMAI_SSL_HOST__;
const FASTLY_SSL_HOST = __FASTLY_SSL_HOST__;
const PROD_FASTLY_SSL_HOST = __PROD_FASTLY_SSL_HOST__;
const METRICS_HOST = __METRICS_HOST__;

const DEFAULT_PROTOCOL = (() => {
  if (typeof window !== 'undefined' && root === window && root.location) {
    return root.location.protocol;
  }
  return 'https:';
})();

const sslOrNonSsl = (protocol, sslHost, nonSslHost) => {
  return protocol === 'https:' ? sslHost : nonSslHost;
};

export const deliveryHost = (protocol = DEFAULT_PROTOCOL) => {
  return sslOrNonSsl(protocol, DELIVERY_SSL_HOST, DELIVERY_HOST);
};

export const akamaiHost = (protocol = DEFAULT_PROTOCOL, embedHost) => {
  // Under normal circumstances, the host for assets should depend on the
  // environment – but if you're using the embedHost option to use non-prod
  // code with prod embeds, we force using a prod host.
  if (embedHost === PROD_CDN_FAST_WISTIA_COM || embedHost === PROD_CDN_FAST_WISTIA_NET) {
    // This is to support running local player-modern code against production embeds.
    return prodAkamaiHost(protocol);
  }
  // Even though the return value above this one says "prod", this one can be
  // prod too - when this code is running in prod. The above case is only for
  // when we want to use the prod host with non-prod code.
  return sslOrNonSsl(protocol, AKAMAI_SSL_HOST, AKAMAI_HOST);
};

export const prodAkamaiHost = (protocol = DEFAULT_PROTOCOL) => {
  return sslOrNonSsl(protocol, PROD_AKAMAI_SSL_HOST, PROD_AKAMAI_HOST);
};

export const fastlyHost = (_protocol = DEFAULT_PROTOCOL, embedHost) => {
  // Under normal circumstances, the host for assets should depend on the
  // environment – but if you're using the embedHost option to use non-prod
  // code with prod embeds, we force using a prod host.
  if (embedHost === PROD_CDN_FAST_WISTIA_COM || embedHost === PROD_CDN_FAST_WISTIA_NET) {
    // This is to support running local player-modern code against production embeds.
    return PROD_FASTLY_SSL_HOST;
  }
  // Even though the return value above this one says "prod", this one can be
  // prod too - when this code is running in prod. The above case is only for
  // when we want to use the prod host with non-prod code.
  return FASTLY_SSL_HOST;
};

export const appHost = () => {
  return APP_WISTIA_COM;
};

export const cdnFastWistiaComHost = (embedHost) => {
  if (embedHost) {
    return embedHost;
  }
  return CDN_FAST_WISTIA_COM;
};

export const cdnFastWistiaNetHost = () => {
  return CDN_FAST_WISTIA_NET;
};

// We run this immediately because we'll be guaranteed that E-v1 is in the DOM
// when this is executing--unless we're loading a legacy thing like
// playlist-v1.js or embed-shepherd_v1.js. We can use the result to determine
// what host we should load other dynamic assets on.
export const eV1Url = (() => {
  const scripts = document.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i++) {
    const s = scripts[i];
    if (s.src) {
      const url = new Url(s.src);

      // Check that this is definitely our script.
      const pathIsEv1 = /\/assets\/external\/E-v1(-with-vulcan-v2)?\.js$/.test(url.rawPath);
      const hasKnownHost =
        url.host === cdnFastWistiaComHost() || url.host === cdnFastWistiaNetHost();

      // Check that the script's protocol is sensible for the page we're on.
      const usesHttpsIfRequired = location.protocol === 'https:' && url.protocol === 'https:';
      const isProtocolRelative = url.protocol === '' || url.protocol == null;
      const protoIsSane =
        usesHttpsIfRequired || isProtocolRelative || location.protocol === 'http:';

      // It's possible there are multiple versions of the script and one failed
      // to load. Let's use the domain that succeeded.
      const scriptHasLoaded = !s.readyState || /loaded|complete/.test(s.readyState);

      if (pathIsEv1 && hasKnownHost && protoIsSane && scriptHasLoaded) {
        return url;
      }
    }
  }

  // If we can't find an instance of E-v1 matching
  return new Url(`${urlProto()}//${cdnFastWistiaNetHost()}/E-v1.js`);
})();

export const eV1Host = () => {
  return eV1Url.host;
};

export const eV1HostWithPort = () => {
  if (eV1Url.port) {
    return `${eV1Host()}:${eV1Url.port}`;
  }

  return eV1Host();
};

export const eV1Protocol = () => {
  return eV1Url.protocol;
};

export const mediaDataHost = (options = {}) => {
  // If embedHost is specified, just use that.
  if (options.embedHost) {
    return forceValidFastWistiaHost(options.embedHost);
  }

  // Try to match whatever host E-v1 is set to by default.
  return eV1HostWithPort();
};

export const metricsHost = () => {
  return METRICS_HOST;
};

export const VALID_PRODUCTION_HOSTS = ['wistia.net', 'wistia.com', 'wistia.st'];
const VALID_PRODUCTION_HOSTS_R = new RegExp(
  `(${VALID_PRODUCTION_HOSTS.map((h) => `\\.${h.replace('.', '\\.')}`).join('|')})$`,
);

export const forceValidFastWistiaHost = (host) => {
  if (host && VALID_PRODUCTION_HOSTS_R.test(host)) {
    return host;
  }

  return eV1HostWithPort();
};

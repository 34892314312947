import CustomEventsWrapper from '../../../../../../shared/CustomEventsWrapper.jsx';
import { h, render, Component } from 'preact';
import { interFontFamily } from 'utilities/interFontFamily.js';
import { isMouseDown } from 'utilities/isMouseDown.js';

class ClickForSoundButton extends Component {
  render() {
    const { elemRef } = this.props;
    return (
      <CustomEventsWrapper
        class="w-css-reset w-css-reset-tree"
        data-handle="click-for-sound-backdrop"
        elemRef={elemRef}
        onClick={this.props.onClick}
        style={this.rootStyle()}
      >
        <button
          aria-label={this.props.buttonText}
          style={this.buttonStyle()}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          class="w-vulcan-v2-button"
        >
          <svg viewBox="0 0 237 237">
            <style>{this.styleText()}</style>
            <polygon fill="white" points="88 107 65 107 65 131 89 131 112 154 112 84" />
            <g fill="none" stroke="white" stroke-width="10" stroke-linecap="round">
              <path class="volume__small-wave" d="M 142 86 C 151 107 151 130 142 151" />
              <path class="volume__large-wave" d="M 165 74 C 178 97 178 140 165 163" />
            </g>
          </svg>
        </button>
      </CustomEventsWrapper>
    );
  }

  styleText() {
    return `
      @keyframes VOLUME_SMALL_WAVE_FLASH {
          0% { opacity: 0; }
         33% { opacity: 1; }
         66% { opacity: 1; }
        100% { opacity: 0; }
      }

      @keyframes VOLUME_LARGE_WAVE_FLASH {
          0% { opacity: 0; }
         33% { opacity: 1; }
         66% { opacity: 1; }
        100% { opacity: 0; }
      }

      .volume__small-wave {
        animation: VOLUME_SMALL_WAVE_FLASH 2s infinite;
        opacity: 0;
      }

      .volume__large-wave {
        animation: VOLUME_LARGE_WAVE_FLASH 2s infinite .3s;
        opacity: 0;
      }
    `;
  }

  rootStyle() {
    return {
      display: this.props.isVisible ? 'block' : 'none',
      height: '100%',
      left: 0,
      pointerEvents: this.props.backdropEnabled ? 'auto' : 'none',
      position: 'absolute',
      top: 0,
      width: '100%',
    };
  }

  buttonStyle() {
    const scale = this.props.scale;
    return {
      background: 'rgba(0,0,0,0.8)',
      border: `2px solid ${this.state.isKeyboardFocused ? '#fff' : 'transparent'}`,
      borderRadius: '50%',
      cursor: 'pointer',
      height: `${35 * scale}px`,
      width: `${35 * scale}px`,
      lineHeight: `${35 * scale}px`,
      outline: 'none',
      pointerEvents: 'auto',
      position: 'absolute',
      right: `${15 * scale - 2}px`,
      textAlign: 'left',
      top: `${15 * scale - 2}px`,
    };
  }

  textStyle() {
    const scale = this.props.scale;
    return {
      color: 'white',
      display: 'inline-block',
      fontFamily: interFontFamily,
      fontSize: `${13 * scale}px`,
      marginLeft: `${-4 * scale}px`,
      marginTop: `${-0.75 * scale}px`,
      verticalAlign: 'top',
      whiteSpace: 'nowrap',
      width: '36%',
    };
  }

  onFocus = () => {
    if (!isMouseDown()) {
      this.setState({ isKeyboardFocused: true });
    }
  };

  onBlur = () => {
    this.setState({ isKeyboardFocused: false });
  };
}

export default ClickForSoundButton;

import W from 'wistia_namespace.js';
import AudioDescriptionButton from './AudioDescriptionButton.jsx';
import AudioDescriptionMenu from './AudioDescriptionMenu.jsx';
import Control from '../../../shared/controls/Control.js';
import { destroyControl } from '../../../../../../shared/control-lifecycle.js';
import { h, render } from 'preact';
import { getTranslation, defineTranslations } from '../../../../../../shared/translations.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { defineControl } from 'embeds/shared/control_definitions.js';

defineTranslations('en-US', {
  AUDIO_DESCRIPTION_HIDE_MENU: 'Hide audio description menu',
  AUDIO_DESCRIPTION_SHOW_MENU: 'Show audio description menu',
});

class AudioDescriptionControl extends Control {
  constructor(video) {
    super(video);
    this.video = video;

    this.unbinds.push(
      video.on('playerlanguagechange', () => {
        if (this.menuElem) {
          this.renderMenu();
        }
      }),

      video.on('audiostreamchange', () => {
        if (this.menuElem) {
          this.renderMenu();
        }
      }),
    );
  }

  controlDialogOpened() {
    this.updateButtonLabel();
  }

  controlDialogClosed() {
    this.updateButtonLabel();
  }

  destroy() {
    destroyControl(this);
  }

  mountButton(buttonRoot) {
    this.buttonRoot = buttonRoot;
    this.renderButton();
  }

  mountDialog(dialogRoot) {
    this.dialogRoot = dialogRoot;
    const codeLoaded = dynamicImport('interFontFace.js').then(() => {
      this.renderMenu();
    });

    this.loading(
      new Promise((resolve) => {
        codeLoaded.then(resolve);
      }),
    );

    return codeLoaded;
  }

  onControlPropsUpdated(prevProps) {
    if (this.dialog && this.dialog.isOpen()) {
      this.renderMenu();
    }

    if (
      prevProps.playerLanguage &&
      this.props.playerLanguage.code !== prevProps.playerLanguage.code
    ) {
      this.renderButton();
      this.renderMenu();
    }
  }

  renderButton() {
    this.updateButtonLabel();

    render(<AudioDescriptionButton />, this.buttonRoot);
    this.reactMounts.button = [this.buttonRoot];
  }

  renderMenu() {
    if (!this.dialog.isOpen()) {
      return;
    }

    if (!this.dialogRoot) {
      return;
    }

    const allAudioTracks = this.video.engine.getAudioTracks();

    render(
      <AudioDescriptionMenu
        {...this.props}
        elemRef={(elem) => (this.menuElem = elem)}
        onClick={this.onClickTrack}
        scale={this.props.scale}
        tracks={allAudioTracks}
      />,
      this.dialogRoot,
    );

    this.reactMounts.menu = [this.dialogRoot];
  }

  updateButtonLabel() {
    if (!this.dialog) {
      return;
    }
    if (this.dialog.isOpen()) {
      this.setButtonLabel(this.translate('HIDE_MENU'));
    } else {
      this.setButtonLabel(this.translate('SHOW_MENU'));
    }
  }

  translate(key) {
    return getTranslation(this.props.playerLanguage.code, `AUDIO_DESCRIPTION_${key}`);
  }

  onClickTrack = (id) => {
    const currentId = this.video.engine.getCurrentAudioTrackId();
    if (Number(currentId) !== Number(id)) {
      this.video.changeAudioTrack(id);
    }
  };
}

AudioDescriptionControl.shouldMount = (video) => {
  if (
    video._opts.audioDescriptionIsRequired !== false &&
    video._impl &&
    video._impl.getAudioTracks &&
    video._impl.getAudioTracks().length > 1
  ) {
    return true;
  }
  return false;
};

AudioDescriptionControl.handle = 'audioDescriptionButton';
AudioDescriptionControl.type = 'control-bar-right';
AudioDescriptionControl.sortValue = 51;
AudioDescriptionControl.isVideoChrome = true;
defineControl(AudioDescriptionControl);

W.AudioDescriptionControl = AudioDescriptionControl;

export default AudioDescriptionControl;

import W from 'wistia_namespace.js';
import { onDocReady } from 'utilities/docReady.js';
import { doTimeout } from 'utilities/timeout-utils.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';

// Check for presence of embed links, and load the rest of the code if we spot
// any clues, such as:
//
// - Any embeds with playlistLinks embed option.
// - Any <a> tags that look like href="#wistia_hashedid"
// - If W.EmbedLink.setup() or W.EmbedLink.setupAll() is called.

W._initializers.initEmbedLinks = () => {
  window.wistiaEmbeds.bind('initembed', () => {
    doTimeout('maybe_load_embed_links_1', maybeLoadEmbedLinks, 1);
    doTimeout('maybe_load_embed_links_1000', maybeLoadEmbedLinks, 1000);
    doTimeout('maybe_load_embed_links_3000', maybeLoadEmbedLinks, 3000);
  });
  onDocReady(() => {
    maybeLoadEmbedLinks();
    doTimeout('maybe_load_embed_links_5000', maybeLoadEmbedLinks, 5000);
  });
};

W._destructors.destroyEmbedLinks = () => {
  if (W.EmbedLink.EmbedLinkClass) {
    W.EmbedLink.EmbedLinkClass._onEv1Destroy();
  }
};

const loadEmbedLinks = () => {
  return dynamicImport('embedLinks.js');
};

const shouldLoadEmbedLinks = () => {
  // Do any embeds have the playlistLinks option?
  const videos = W.api._apiHandlesByDomOrder();
  for (let i = 0; i < videos.length; i++) {
    if (videos[i]._opts && videos[i]._opts.playlistLinks) {
      return true;
    }
  }

  // Are there any <a> tags that have #wistia_hashedid as their src?
  if (document.querySelectorAll('a[href^="#wistia_"]').length > 0) {
    return true;
  }

  return false;
};

const maybeLoadEmbedLinks = () => {
  if (shouldLoadEmbedLinks()) {
    loadEmbedLinks();
  }
};

W.EmbedLink = {};

W.EmbedLink.setup = (...args) => {
  return loadEmbedLinks().then(() => {
    W.EmbedLink.EmbedLinkClass.setup(...args);
  });
};

W.EmbedLink.setupAll = (...args) => {
  return loadEmbedLinks().then(() => {
    W.EmbedLink.EmbedLinkClass.setupAll(...args);
  });
};

W.EmbedLink.destroyAll = (...args) => {
  return loadEmbedLinks().then(() => {
    W.EmbedLink.EmbedLinkClass.destroyAll(...args);
  });
};

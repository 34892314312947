var cachedDetect;

cachedDetect = require('utilities/detect.js').cachedDetect;

(function(W) {
  return W._initializers.initDetect = function() {
    if (!W.detect) {
      return W.detect = cachedDetect();
    }
  };
})(Wistia);

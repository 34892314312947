import { dynamicImport } from 'utilities/dynamicImport.ts';

(function (Wistia) {
  const W = Wistia;

  if (!W.mux) {
    W.mux = {};
  }

  W._initializers.initMux = () => {
    if (W.mux._setup) {
      return;
    }

    // to save on COGS, we don't want to initialize Mux
    // on every load. Do a coin flip to see if we should init
    const percentageToInitialize = 0.1;
    let shouldRandomDisableMux;
    const cryptoObj = window.crypto || window.msCrypto;
    if (typeof cryptoObj !== 'undefined') {
      const cryptoRandom = cryptoObj.getRandomValues(new Uint32Array(1));
      shouldRandomDisableMux = cryptoRandom[0] / (0xffffffff + 1) >= percentageToInitialize;
    } else {
      const mathRandom = Math.random();
      shouldRandomDisableMux = Number(mathRandom >= percentageToInitialize);
    }

    if (shouldRandomDisableMux) {
      W.mux._disabled = true;
      return;
    }

    W.mux._setup = true;
    W.mux._disabled = false;

    W.api.onFind((video) => {
      const shouldDisableMux = () => {
        return window.wistiaDisableMux;
      };

      if (shouldDisableMux()) {
        return;
      }

      if (!W.mux._disabled && !video.iframe && (!video._opts || video._opts.mux !== false)) {
        dynamicImport('wistia-mux.js').then((mod) => {
          video.embedded(() => {
            if (shouldDisableMux()) {
              return;
            }

            mod.init(video);
          });
        });
      }
    });
  };

  W._destructors.destroyMux = () => {
    W.mux._disabled = true;
    const all = W.api.all();
    for (let v = 0; v < all.length; v++) {
      all[v].mux?.destroy();
    }
  };
})(window.Wistia);

import W from 'wistia_namespace.js';
import assign from 'utilities/assign.js';
import pageLoaded from 'utilities/pageLoaded.js';
import { isVisitorTrackingEnabled } from 'utilities/trackingConsentApi.js';
import { xhrPost } from 'utilities/xhr.js';

if (W._simpleMetricsCache == null) {
  W._simpleMetricsCache = {};
}

if (!W._simpleMetricsPostFunction) {
  W._simpleMetricsPostFunction = xhrPost;
}

if (W._simpleMetricsDebounceInterval == null) {
  W._simpleMetricsDebounceInterval = 500;
}

const METRICS_CACHE = W._simpleMetricsCache;

export const countMetric = (key, val = 1, extraData) => {
  return sendMetric('count', key, val, extraData);
};

export const sendMetrics = (...messages) => {
  if (!isVisitorTrackingEnabled()) {
    return;
  }
  const url = `https://${__METRICS_HOST__}/mput?topic=metrics`;
  return W._simpleMetricsPostFunction(url, messages.join('\n'));
};

export const sampleMetric = (key, val, extraData) => {
  return sendMetric('sample', key, val, extraData);
};

export const sendMetric = (type, key, val, extraData = {}) => {
  if (!isVisitorTrackingEnabled()) {
    return;
  }
  try {
    if (METRICS_CACHE.toMput == null) {
      METRICS_CACHE.toMput = [];
    }

    const messageObj = assign(
      {
        type,
        key,
        value: val != null ? val : null,
      },
      extraData,
    );

    const serialized = JSON.stringify(messageObj);
    METRICS_CACHE.toMput.push(serialized);

    clearTimeout(W._msendTimeout);
    W._msendTimeout = setTimeout(() => {
      pageLoaded(() => {
        sendMetrics.apply(this, METRICS_CACHE.toMput);
        METRICS_CACHE.toMput = [];
      });
    }, W._simpleMetricsDebounceInterval);
  } catch (e) {
    console.error(e.message);
    console.error(e.stack);
  }
};

// only used in testing to clear the cache
export const _clearMetricsCache = () => {
  METRICS_CACHE.toMput = [];
};

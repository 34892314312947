/* global globalThis */

let root;
try {
  root = self; // dom or web worker
  if (root.self !== root && typeof root.self !== 'undefined' && typeof window !== 'undefined') {
    // Accidentally assigning `self` is a thing that websites do. But most of
    // these accidental assigns won't have a self property pointing at themselves.
    root = window;
  }
} catch (err) {
  if (typeof globalThis !== 'undefined') {
    root = globalThis; // es native in the future
  } else {
    // oh god, what's even happening
    root = window;
  }
}
export default root;

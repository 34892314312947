var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

(function(Wistia) {
  var DISTILLERY_PREFIX, W, globalBind, isVisitorTrackingEnabled, uniqId;
  W = Wistia;
  if (W.VisitorKey) {
    return;
  }
  globalBind = require('utilities/globalBindAndTrigger.js').globalBind;
  isVisitorTrackingEnabled = require('utilities/trackingConsentApi.js').isVisitorTrackingEnabled;
  uniqId = require('utilities/uniqId.js').uniqId;
  DISTILLERY_PREFIX = '__distillery';
  globalBind('visitortrackingchange', function(val, domain) {
    var base, i, isEnabled, len, ref, v;
    ref = W.api.all();
    for (i = 0, len = ref.length; i < len; i++) {
      v = ref[i];
      if (typeof v._postDownVisitorTrackingEnabled === "function") {
        v._postDownVisitorTrackingEnabled();
      }
    }
    if (W.visitorKey) {
      isEnabled = isVisitorTrackingEnabled();
      if (isEnabled) {
        if (W.visitorKey.value()) {
          return W.visitorKey.persist(W.visitorKey.value());
        }
      } else {
        W.visitorKey.unpersist();
        return typeof (base = W._destructors).destroyMux === "function" ? base.destroyMux() : void 0;
      }
    }
  });
  W.VisitorKey = (function() {
    function VisitorKey() {
      this.ready = bind(this.ready, this);
      this.unbinds = [];
      setTimeout((function(_this) {
        return function() {
          return _this.unbinds.push(W.api.onFind(function(video) {
            return video.hasData(function() {
              var ref;
              if (!video.iframe && ((ref = video._mediaData) != null ? ref.privacyMode : void 0) === true) {
                _this.unpersist();
              }
              return _this.ready();
            });
          }));
        };
      })(this), 0);
    }

    VisitorKey.prototype.ready = function() {
      if (this._readyPromise) {
        return this._readyPromise;
      }
      this._readyPromise = new Promise((function(_this) {
        return function(resolve) {
          if (!_this.savedValue()) {
            _this.persist(W.VisitorKey.generate());
          }
          return resolve();
        };
      })(this));
      return this._readyPromise;
    };

    VisitorKey.prototype.reinitialize = function() {
      this.destroy();
      this.unbinds = [];
      this._readyPromise = null;
      return this.ready();
    };

    VisitorKey.prototype.persist = function(newValue) {
      if (!W.VisitorKey.isValid(newValue)) {
        this.error(new Error("Attempted to persist invalid visitor key '" + newValue + "'"));
        return;
      }
      this.info('@persist', newValue);
      this._value = newValue;
      if (!isVisitorTrackingEnabled()) {
        return;
      }
      if (window.wistiaDisableCookies !== true && this.localStorageValue() !== this._value) {
        return W.localStorage(DISTILLERY_PREFIX, this._value);
      }
    };

    VisitorKey.prototype.unpersist = function() {
      return this.clearFromCurrentDomain();
    };

    VisitorKey.prototype.clear = function() {
      this.info('clear');
      this.unpersist();
      return this._value = null;
    };

    VisitorKey.prototype.clearFromCurrentDomain = function() {
      return W.removeLocalStorage(DISTILLERY_PREFIX, null);
    };

    VisitorKey.prototype.value = function() {
      var result;
      result = this._value || this.savedValue();
      if (!result) {
        result = W.VisitorKey.generate();
        this.persist(result);
      }
      return result;
    };

    VisitorKey.prototype.savedValue = function() {
      if (isVisitorTrackingEnabled()) {
        return this.localStorageValue();
      } else {
        return void 0;
      }
    };

    VisitorKey.prototype.localStorageValue = function() {
      return W.localStorage(DISTILLERY_PREFIX);
    };

    VisitorKey.prototype.isValid = function() {
      return W.VisitorKey.isValid(this.value());
    };

    VisitorKey.prototype.destroy = function() {
      var i, len, ref, u;
      ref = this.unbinds;
      for (i = 0, len = ref.length; i < len; i++) {
        u = ref[i];
        u();
      }
      return this.unbinds = [];
    };

    VisitorKey.isValid = function(key) {
      return (key != null ? key.length : void 0) > 25 && /^[a-z0-9_\-\.]+$/i.test(key);
    };

    VisitorKey.generate = function() {
      return uniqId((__FINGERPRINT_SHA__.substring(0, 7)) + "_");
    };

    return VisitorKey;

  })();
  W.mixin(W.VisitorKey.prototype, W.logHelpers);
  W._initializers.initVisitorKey = function() {
    return W.visitorKey = new W.VisitorKey();
  };
  return W._destructors.destroyVisitorKey = function() {
    var ref;
    if ((ref = W.visitorKey) != null) {
      ref.destroy();
    }
    return W.visitorKey = null;
  };
})(Wistia);

var core;

core = require('utilities/core.js');

if (!Wistia.base64) {
  Wistia.base64 = {};
}

if (!Wistia.base64.decode) {
  Wistia.base64._keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  Wistia.base64.encode = core.base64Encode;
}

(function(W) {
  var Elem;
  Elem = require('utilities/elem.js');
  if (W._onFullscreenChange == null) {
    W._onFullscreenChange = function() {
      if (Elem.fullscreenElement()) {
        return W.trigger('enterfullscreen', Elem.fullscreenElement());
      } else {
        return W.trigger('cancelfullscreen');
      }
    };
  }
  W._initializers.initFullscreenTriggers = function() {
    Elem.elemRebind(document, 'mozfullscreenchange', W._onFullscreenChange);
    Elem.elemRebind(document, 'webkitfullscreenchange', W._onFullscreenChange);
    Elem.elemRebind(document, 'MSFullscreenChange', W._onFullscreenChange);
    return Elem.elemRebind(document, 'fullscreenchange', W._onFullscreenChange);
  };
  return W._destructors.destroyFullscreenTriggers = function() {
    Elem.elemUnbind(document, 'mozfullscreenchange', W._onFullscreenChange);
    Elem.elemUnbind(document, 'webkitfullscreenchange', W._onFullscreenChange);
    Elem.elemUnbind(document, 'MSFullscreenChange', W._onFullscreenChange);
    return Elem.elemUnbind(document, 'fullscreenchange', W._onFullscreenChange);
  };
})(Wistia);

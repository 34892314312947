(function(W) {
  var StopGo, assign, asyncChain, cast, clearTimeouts, clone, elemClasses, elemHasClass, elemMutationObserver, isObject, onDocReady, queryParamsToObject, ref, ref1, throttle, wlog;
  if (W.embeds) {
    return;
  }
  W.embeds = {};
  StopGo = require('utilities/stopgo.coffee');
  wlog = require('utilities/wlog.js').wlog;
  onDocReady = require('utilities/docReady.js').onDocReady;
  asyncChain = require('utilities/asyncChain.js').asyncChain;
  throttle = require('utilities/core.js').throttle;
  ref = require('utilities/elem.js'), elemClasses = ref.elemClasses, elemHasClass = ref.elemHasClass, elemMutationObserver = ref.elemMutationObserver;
  queryParamsToObject = require('utilities/url.js').queryParamsToObject;
  clearTimeouts = require('utilities/timeout-utils.js').clearTimeouts;
  ref1 = require('utilities/obj.js'), cast = ref1.cast, isObject = ref1.isObject, assign = ref1.assign, clone = ref1.clone;
  W.embeds.setup = function(embedElems) {
    return W.embeds.notEmbedding(function() {
      var doEmbedding;
      doEmbedding = function() {
        var e1, e2, elem, functions, hashedId, j, len, newId, options;
        W.embeds.notEmbedding(false);
        functions = [];
        try {
          embedElems = W.embeds.uninitialized(embedElems);
          if (embedElems && embedElems.length && embedElems.length > 0) {
            W.info('initializing', embedElems != null ? embedElems.length : void 0, 'embedElems');
          }
          for (j = 0, len = embedElems.length; j < len; j++) {
            elem = embedElems[j];
            try {
              W.info('initializing elem', elem);
              hashedId = W.embeds.hashedIdForElem(elem, {
                asyncOnly: true
              });
              if (elem.id) {
                if (document.getElementById(elem.id) !== elem) {
                  newId = W.embeds.genIdFor(hashedId);
                  W.error("Duplicate DOM ID found for container \"" + elem.id + "\". Changing it to \"" + newId + "\" to avoid issues.", elem);
                  elem.id = newId;
                }
              } else {
                elem.id = W.embeds.genIdFor(hashedId);
              }
              options = W.embeds.optionsFromElemClass(elem);
              W.options("__" + elem.id + "_dom_options__", options);
              (function(hashedId, elem) {
                return functions.push(function() {
                  if (!W.embeds.containerIsInitialized(elem)) {
                    return W.embed(hashedId, {
                      container: elem.id
                    });
                  }
                });
              })(hashedId, elem);
            } catch (error) {
              e1 = error;
              W.error(e1);
            }
          }
        } catch (error) {
          e2 = error;
          W.error(e2);
        }
        functions.push(function() {
          return W.watchForInit();
        });
        functions.push(function() {
          return W.embeds.notEmbedding(true);
        });
        return asyncChain('embeds_setup', functions);
      };
      onDocReady(doEmbedding);
      if (W.detect.ios.version === 0 && !W.detect.safari) {
        return doEmbedding();
      }
    });
  };
  W.embeds._setupThrottled = throttle(500, W.embeds.setup);
  W.embeds.optionsFromElemClass = function(elem) {
    var j, klass, len, optionClasses, options, ref2;
    optionClasses = [];
    ref2 = elemClasses(elem);
    for (j = 0, len = ref2.length; j < len; j++) {
      klass = ref2[j];
      if (klass.indexOf('=') > 0) {
        optionClasses.push(klass);
      }
    }
    options = queryParamsToObject(optionClasses.join('&'));
    cast(options);
    return options;
  };
  W.embeds.hashedIdForElem = function(elem, options) {
    var ref2, ref3, ref4, ref5, ref6, ref7;
    if (options == null) {
      options = {};
    }
    if (options.asyncOnly) {
      return ((ref2 = elem.className) != null ? (ref3 = ref2.match(/wistia_async_([^\s]+)/)) != null ? ref3[1] : void 0 : void 0) || null;
    } else {
      return ((ref4 = elem.className) != null ? (ref5 = ref4.match(/wistia_async_([^\s]+)/)) != null ? ref5[1] : void 0 : void 0) || ((ref6 = elem.className) != null ? (ref7 = ref6.match(/wistia_([^\s]+)/)) != null ? ref7[1] : void 0 : void 0) || null;
    }
  };
  W.embeds.genIdFor = function(hashedId) {
    var base, candidate, i;
    base = "wistia-" + hashedId;
    i = 1;
    while (true) {
      candidate = base + "-" + i;
      if (!document.getElementById(candidate)) {
        break;
      }
      i += 1;
    }
    return candidate;
  };
  W.embeds._options = {};
  W.embeds.options = function(id, options) {
    var handleFromId, k, ref2, v;
    if (isObject(id)) {
      options = id;
      id = '__global__';
    }
    if (options != null) {
      return W.embeds._options[id] = cast(clone(options));
    } else {
      if (id) {
        if (W.embeds._options[id]) {
          return W.embeds._options[id];
        } else {
          handleFromId = W.api(id);
          if (handleFromId === null) {
            return {};
          }
          ref2 = W.embeds._options;
          for (k in ref2) {
            v = ref2[k];
            if (W.api(k) === handleFromId) {
              return v;
            }
          }
          return {};
        }
      } else {
        return W.embeds._options;
      }
    }
  };
  W.options = W.embeds.options;
  W.embeds.api = function(klass) {
    var divEls, divs, el, ref2, ref3, spanEls, spans;
    if (klass == null) {
      klass = 'wistia_embed';
    }
    if (document.querySelectorAll) {
      return document.querySelectorAll("div." + klass + ",span." + klass);
    } else {
      divs = ((ref2 = document.body) != null ? ref2.getElementsByTagName('div') : void 0) || [];
      spans = ((ref3 = document.body) != null ? ref3.getElementsByTagName('span') : void 0) || [];
      divEls = (function() {
        var j, len, results;
        results = [];
        for (j = 0, len = divs.length; j < len; j++) {
          el = divs[j];
          if (elemHasClass(el, klass)) {
            results.push(el);
          }
        }
        return results;
      })();
      spanEls = (function() {
        var j, len, results;
        results = [];
        for (j = 0, len = spans.length; j < len; j++) {
          el = spans[j];
          if (elemHasClass(el, klass)) {
            results.push(el);
          }
        }
        return results;
      })();
      return divEls.concat(spanEls);
    }
  };
  W.embeds.uninitialized = function(embedElems) {
    var elem, hashedId, j, len, result;
    if (embedElems == null) {
      embedElems = W.embeds.api();
    }
    result = [];
    for (j = 0, len = embedElems.length; j < len; j++) {
      elem = embedElems[j];
      hashedId = W.embeds.hashedIdForElem(elem, {
        asyncOnly: true
      });
      if (hashedId && !W.embeds.containerIsInitialized(elem)) {
        result.push(elem);
      }
    }
    return result;
  };
  W.embeds.containerIsInitialized = (function(_this) {
    return function(container) {
      return container && container.wistiaApi && !(container.wistiaApi instanceof W.IframeApiProxy);
    };
  })(this);
  W.embeds.initialized = function(embedElems) {
    var handle, ref2, results, uuid;
    ref2 = W._data.video;
    results = [];
    for (uuid in ref2) {
      handle = ref2[uuid];
      results.push(handle);
    }
    return results;
  };
  W.embeds.observe = function() {
    var config, e;
    if (W.embeds.initObserver) {
      return;
    }
    W.embeds.initObserver = elemMutationObserver(function(mutations) {
      var addedNodes, embedElems, j, len, len1, m, mutation, node, ref2, ref3;
      embedElems = [];
      addedNodes = false;
      for (j = 0, len = mutations.length; j < len; j++) {
        mutation = mutations[j];
        if (((ref2 = mutation.addedNodes) != null ? ref2.length : void 0) > 0) {
          addedNodes = true;
        }
        ref3 = mutation.addedNodes || [];
        for (m = 0, len1 = ref3.length; m < len1; m++) {
          node = ref3[m];
          if (elemHasClass(node, 'wistia_embed')) {
            embedElems.push(node);
          }
        }
      }
      if (embedElems.length > 0) {
        setTimeout((function() {
          return W.embeds.setup(embedElems);
        }), 10);
      }
      if (addedNodes) {
        return W.embeds._setupThrottled();
      }
    });
    config = {
      subtree: true,
      childList: true
    };
    if (W.detect.ios.version > 0 || W.detect.safari) {
      return onDocReady(function() {
        return W.embeds.initObserver.observe(document.body, config);
      });
    } else {
      try {
        return W.embeds.initObserver.observe(document.body, config);
      } catch (error) {
        e = error;
        wlog.notice(e);
        return onDocReady(function() {
          try {
            return W.embeds.initObserver.observe(document.body, config);
          } catch (error) {
            e = error;
            return wlog.error(e);
          }
        });
      }
    }
  };
  W.embeds.unobserve = function() {
    var ref2;
    if ((ref2 = W.embeds.initObserver) != null) {
      ref2.disconnect();
    }
    return W.embeds.initObserver = null;
  };
  W.embeds.poll = function(interval) {
    if (interval == null) {
      interval = 500;
    }
    return W.eventLoop.add('poll_setup_embeds', interval, function() {
      return W.embeds.setup();
    });
  };
  W.embeds.unpoll = function() {
    var ref2;
    return (ref2 = W.eventLoop) != null ? ref2.remove('poll_setup_embeds') : void 0;
  };
  W.embeds.watch = function() {
    W.embeds._dontWatch = false;
    if (W.detect.mutationObserver) {
      W.embeds.observe();
      return W.embeds.poll(2000);
    } else {
      return W.embeds.poll();
    }
  };
  W.embeds.dontWatch = function() {
    W.embeds._dontWatch = true;
    return W.embeds.unwatch();
  };
  W.embeds.unwatch = function() {
    W.embeds.unobserve();
    return W.embeds.unpoll();
  };
  W.flushInit = function() {
    var e, fn, j, len;
    if (!window.wistiaInit) {
      return;
    }
    if (wistiaInit instanceof Array) {
      for (j = 0, len = wistiaInit.length; j < len; j++) {
        fn = wistiaInit[j];
        try {
          if (typeof fn === "function") {
            fn(W);
          }
        } catch (error) {
          e = error;
          wlog.error(e);
        }
      }
    } else {
      if (typeof wistiaInit === "function") {
        wistiaInit(W);
      }
    }
    return window.wistiaInit = null;
  };
  W.addQueue = function(queueName) {
    W._queueNames.push(queueName);
    return W.watchForInit();
  };
  W._queueNames = ['_wq', 'wistiaInitQueue'];
  W._wqBoundListeners = [];
  W.flushInitQueue = function() {
    var e, fn, fn1, fnQueue, j, key, len, len1, m, queue, queueName, ran, ref2, thisElement, val;
    if (W._isFlushingInitQueue) {
      return;
    }
    W._isFlushingInitQueue = true;
    try {
      ref2 = W._queueNames;
      for (j = 0, len = ref2.length; j < len; j++) {
        queueName = ref2[j];
        queue = window[queueName];
        if (!(queue && queue instanceof Array && queue.length > 0)) {
          continue;
        }
        fnQueue = [];
        while (queue.length > 0) {
          thisElement = queue.shift();
          if (isObject(thisElement)) {
            if (thisElement.revoke) {
              W.revokeQueueListener(thisElement.revoke);
            }
            if (!thisElement.__unbinds) {
              thisElement.__unbinds = [];
            }
            if (thisElement.id) {
              ran = false;
              if (thisElement.options) {
                if (thisElement.id === '_all') {
                  W.options(thisElement.options);
                  ran = true;
                } else {
                  W.options(thisElement.id, thisElement.options);
                  ran = true;
                }
              }
              (function(thisElement) {
                var args, baseArgs;
                baseArgs = thisElement.id === '_all' ? [] : [thisElement.id];
                if (thisElement.onFind) {
                  args = baseArgs.concat([
                    function(video) {
                      return thisElement.onFind(video);
                    }
                  ]);
                  thisElement.__unbinds = thisElement.__unbinds.concat(W.api.apply(W, args));
                  ran = true;
                }
                if (thisElement.onHasData) {
                  args = baseArgs.concat([
                    function(video) {
                      return video.hasData(function() {
                        return thisElement.onHasData(video);
                      });
                    }
                  ]);
                  thisElement.__unbinds = thisElement.__unbinds.concat(W.api.apply(W, args));
                  ran = true;
                }
                if (thisElement.onEmbedded) {
                  args = baseArgs.concat([
                    function(video) {
                      return video.embedded(function() {
                        return thisElement.onEmbedded(video);
                      });
                    }
                  ]);
                  thisElement.__unbinds = thisElement.__unbinds.concat(W.api.apply(W, args));
                  ran = true;
                }
                if (thisElement.onReady) {
                  args = baseArgs.concat([
                    function(video) {
                      return video.ready(function() {
                        return thisElement.onReady(video);
                      });
                    }
                  ]);
                  thisElement.__unbinds = thisElement.__unbinds.concat(W.api.apply(W, args));
                  ran = true;
                }
                if (ran) {
                  return W._wqBoundListeners.push(thisElement);
                } else {
                  return W.error("No initialization function provided for ID " + thisElement.id, thisElement);
                }
              })(thisElement);
            } else {
              fn1 = function(thisElement, key, val) {
                var fn, runWhenHasData;
                if (typeof val === 'function') {
                  runWhenHasData = function(video) {
                    return video.hasData(function() {
                      return val(video);
                    });
                  };
                  fn = function() {
                    if (key === '_all') {
                      thisElement.__unbinds = thisElement.__unbinds.concat(W.api(runWhenHasData));
                    } else {
                      thisElement.__unbinds = thisElement.__unbinds.concat(W.api(key, runWhenHasData));
                    }
                    return W._wqBoundListeners.push(thisElement);
                  };
                  return fnQueue.push(fn);
                } else if (isObject(val)) {
                  if (key === '_all') {
                    fn = function() {
                      return W.options(val);
                    };
                  } else {
                    fn = function() {
                      return W.options(key, val);
                    };
                  }
                  return fnQueue.push(fn);
                } else if (key === '__unbinds' || key === 'revoke') {

                } else {
                  return W.error('Unknown initialization object:', val, 'Expected Object or Function.');
                }
              };
              for (key in thisElement) {
                val = thisElement[key];
                fn1(thisElement, key, val);
              }
            }
          } else if (typeof thisElement === 'function') {
            (function(thisElement) {
              var fn;
              fn = function() {
                return thisElement(W);
              };
              return fnQueue.push(fn);
            })(thisElement);
          } else {
            W.notice(queueName + ": Don't know what to do with " + thisElement + ", ignoring.");
          }
        }
        for (m = 0, len1 = fnQueue.length; m < len1; m++) {
          fn = fnQueue[m];
          try {
            if (typeof fn === "function") {
              fn(W);
            }
          } catch (error) {
            e = error;
            wlog.error(e);
          }
        }
        queue.length = 0;
      }
    } catch (error) {
      e = error;
      wlog.error(e);
    }
    return W._isFlushingInitQueue = false;
  };
  W.getInitQueueListeners = function() {
    return assign([], W._wqBoundListeners);
  };
  W.revokeQueueListener = function(listener) {
    var j, len, len1, m, ref2, results, toRevoke, u;
    toRevoke = listener instanceof Array ? listener : [listener];
    results = [];
    for (j = 0, len = toRevoke.length; j < len; j++) {
      listener = toRevoke[j];
      if (listener.__unbinds) {
        ref2 = listener.__unbinds;
        for (m = 0, len1 = ref2.length; m < len1; m++) {
          u = ref2[m];
          if (typeof u === "function") {
            u();
          }
        }
        delete listener.__unbinds;
      }
      results.push(W._wqBoundListeners = W._wqBoundListeners.filter(function(l) {
        return l !== listener;
      }));
    }
    return results;
  };
  W.watchForInit = function() {
    if (W._pollInit == null) {
      W._pollInit = function() {
        W.flushInitQueue();
        return W.flushInit();
      };
    }
    W._pollInit();
    if (W.eventLoop) {
      W.eventLoop.unpause('poll_init');
      return W.eventLoop.add('poll_init', 500, W._pollInit);
    }
  };
  W.api = function(matcher, fn) {
    var all, apiHandles, container, handle, index, j, len, query, ref2, ref3, ref4;
    if (matcher != null) {
      if (fn) {
        return W.api.onFind(matcher, fn);
      }
      if (typeof matcher === 'function') {
        return W.api.onFind(matcher);
      }
      if (typeof matcher === 'string') {
        query = matcher;
        container = document.getElementById(query);
        if (!container) {
          apiHandles = W.api.all();
          for (j = 0, len = apiHandles.length; j < len; j++) {
            handle = apiHandles[j];
            if (((ref2 = handle.hashedId()) != null ? ref2.indexOf(query) : void 0) === 0 || ((ref3 = handle.container.id) != null ? ref3.indexOf(query) : void 0) === 0) {
              container = handle.container;
              break;
            }
          }
        }
      } else if (typeof matcher === 'number') {
        index = matcher;
        all = W.api.all();
        if (index < 0) {
          index = all.length + index;
        }
        container = ((ref4 = all[index]) != null ? ref4.container : void 0) || null;
      } else if (window.HTMLElement && matcher instanceof HTMLElement) {
        container = matcher;
      } else {
        W.error('W.api: Unrecognized matcher', matcher);
      }
      if ((container != null ? container.wistiaApi : void 0) && (container.wistiaApi instanceof W.PublicApi || (W.IframeApi && container.wistiaApi instanceof W.IframeApi))) {
        return container.wistiaApi;
      } else {
        return null;
      }
    } else {
      return W.api.all()[0] || null;
    }
  };
  W.api.all = function() {
    return W.api._apiHandles().concat(W.api._iframeHandles());
  };
  W.api._apiHandles = function() {
    return W.embeds.initialized();
  };
  W.api._apiHandlesByDomOrder = function() {
    var c, containers, j, len, results;
    containers = W.embeds.api('wistia_embed_initialized');
    results = [];
    for (j = 0, len = containers.length; j < len; j++) {
      c = containers[j];
      if (c.wistiaApi && c.wistiaApi instanceof W.PublicApi) {
        results.push(c.wistiaApi);
      }
    }
    return results;
  };
  W.api._iframeHandles = function() {
    var handle, ref2, results, signature;
    ref2 = W._data.iframe_api;
    results = [];
    for (signature in ref2) {
      handle = ref2[signature];
      results.push(handle);
    }
    return results;
  };
  W.api.onFind = function(matcher, fn) {
    var runIfMatch, unbinds;
    if (typeof matcher === 'function') {
      fn = matcher;
      matcher = null;
    }
    runIfMatch = function(video) {
      if (matcher === null || W.api(matcher) === video) {
        fn(video);
        return true;
      } else {
        return false;
      }
    };
    wistiaEmbeds.each(runIfMatch);
    unbinds = [wistiaEmbeds.on('initembed', runIfMatch), wistiaEmbeds.on('afterreplace', runIfMatch)];
    return (function(_this) {
      return function() {
        var j, len, results, u;
        results = [];
        for (j = 0, len = unbinds.length; j < len; j++) {
          u = unbinds[j];
          results.push(u());
        }
        return results;
      };
    })(this);
  };
  W._asyncInitSoonAfterLoad = function(delay) {
    if (delay == null) {
      delay = 10;
    }
    return setTimeout(function() {
      var e;
      try {
        W.embeds.setup();
        if (!W.embeds._dontWatch) {
          W.embeds.watch();
        }
        return W.watchForInit();
      } catch (error) {
        e = error;
        return wlog.error(e);
      }
    }, delay);
  };
  W._initializers.initAsyncEmbeds = function() {
    var base1, base2, ref2;
    W.embeds.notEmbedding = new StopGo();
    W.embeds.notEmbedding(true);
    if (W._data == null) {
      W._data = {};
    }
    if ((base1 = W._data).video == null) {
      base1.video = {};
    }
    if ((base2 = W._data).iframe_api == null) {
      base2.iframe_api = {};
    }
    if (window._inWistiaIframe) {
      return setTimeout((function() {
        return W.watchForInit();
      }), 10);
    } else {
      W._asyncInitSoonAfterLoad();
      W._asyncInitSoonAfterLoad(500);
      return (ref2 = W.eventLoop) != null ? ref2.pause('poll_init') : void 0;
    }
  };
  return W._destructors.destroyAsyncEmbeds = function() {
    var ref2, ref3;
    clearTimeouts('embeds_setup');
    if ((ref2 = W.eventLoop) != null) {
      ref2.remove('poll_init');
    }
    return (ref3 = W.embeds) != null ? ref3.unwatch() : void 0;
  };
})(Wistia);

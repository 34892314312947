(function(Wistia) {
  var Judy, W, assign, dynamicImport, fetchMedia, handleReceivedMediaData, mediaDataTransforms, merge;
  W = Wistia;
  if (W.embed) {
    return;
  }
  Judy = require('utilities/judy.js');
  fetchMedia = require('utilities/media_data.js').fetchMedia;
  mediaDataTransforms = require('utilities/media-data-transforms.js').mediaDataTransforms;
  merge = require('utilities/obj.js').merge;
  assign = require('utilities/assign.js')["default"];
  dynamicImport = require('utilities/dynamicImport.ts').dynamicImport;
  W._embed = {};
  W.embed = function(media, options) {
    var apiHandle, hashedId, transformOptions;
    if (options == null) {
      options = {};
    }
    W.info('Wistia.embed', media, options);
    if (typeof W.flushInitQueue === "function") {
      W.flushInitQueue();
    }
    if (typeof media === 'string') {
      hashedId = media;
    } else if (media.type && media.type === 'ab-test') {
      hashedId = media.hashedId;
      [media.control, media.variant].filter(Boolean).forEach(function(mediaResp) {
        return handleReceivedMediaData(mediaResp, options);
      });
      W.cacheAbTest(media.hashedId, media);
    } else {
      hashedId = media.hashedId;
      transformOptions = merge({}, media.embed_options, options);
      mediaDataTransforms(media, transformOptions);
      W.cacheMedia(hashedId, media);
    }
    apiHandle = new W.PublicApi(hashedId, options);
    return apiHandle;
  };
  W.embedEngine = function(container, media, options, judyContext) {
    var hashedId;
    if (options == null) {
      options = {};
    }
    if (judyContext == null) {
      judyContext = {};
    }
    if (typeof media === 'string') {
      hashedId = media;
    } else {
      hashedId = media.hashedId;
    }
    return new Promise(function(resolve) {
      var doEmbed;
      doEmbed = function(mediaData) {
        var ctx, enginePath;
        ctx = Judy.buildContext(judyContext);
        enginePath = Judy.bestUsableEngine(ctx, mediaData, options);
        return dynamicImport(enginePath).then(function(mod) {
          var EngineClass, engine;
          EngineClass = mod["default"];
          engine = new EngineClass(container, mediaData, merge({
            duration: mediaData.duration
          }, options));
          return resolve(engine);
        });
      };
      if (typeof media === 'string') {
        return fetchMedia(hashedId, function(mediaData) {
          mediaDataTransforms(mediaData, options);
          W.cacheMedia(hashedId, mediaData);
          return doEmbed(mediaData);
        }, assign({}, options, {
          errorCallback: (function(_this) {
            return function() {
              return countMetric('player/failure/embed-failed');
            };
          })(this)
        }));
      } else {
        return doEmbed(media);
      }
    });
  };
  return handleReceivedMediaData = function(m, options) {
    mediaDataTransforms(m.media, options);
    return W.cacheMedia(m.media.hashedId, m.media);
  };
})(Wistia);

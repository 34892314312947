import { isValidIso8601, iso8601TimeToSeconds } from './iso8601Helper.js';

const areMeasuresInOrder = (durationString) => {
  const measures = durationString.match(/[hms]/g);
  let isProperOrder = false;

  if (measures.length === 3) {
    if (measures[0] === 'h' && measures[1] === 'm' && measures[2] === 's') {
      return true;
    }
  }

  if (measures.length === 2) {
    const measureStr = measures.join('');
    ['hm', 'hs', 'ms'].forEach((sequence) => {
      if (measureStr === sequence) {
        isProperOrder = true;
      }
    });

    return isProperOrder;
  }

  return true;
};

const doMeasuresRepeat = (durationString) => {
  const filteredMeasures = ['h', 'm', 's'].filter((measure) => {
    const regex = new RegExp(measure, 'g');

    const matches = durationString.match(regex);
    if (matches !== null && matches.length > 1) {
      return true;
    }

    return false;
  });

  return filteredMeasures.length > 0;
};

export const durationStringToSeconds = (duration) => {
  const durationString = duration?.toString() || '';

  if (!isValidDurationString(durationString)) {
    return 0;
  }

  const hoursInSeconds = getValueForDurationKey('h', durationString) * 3600;
  const minutesInSeconds = getValueForDurationKey('m', durationString) * 60;
  const seconds = getValueForDurationKey('s', durationString);

  return hoursInSeconds + minutesInSeconds + seconds;
};

// flexibleDuration is a duration format that uses as
// few digits as possible to express that duration
export const flexibleDuration = (total) => {
  const { hours, minutes, seconds } = secondsConverter(total, 'hms');

  if (hours === 0) {
    return `${minutes}:${padNumber(seconds, 2)}`;
  }
  return `${hours}:${padNumber(minutes, 2)}:${padNumber(seconds, 2)}`;
};

export const formattedDurationToSeconds = (dur) => {
  if (isValidDurationString(dur)) {
    return durationStringToSeconds(dur);
  }
  if (isValidIso8601(dur)) {
    return iso8601TimeToSeconds(dur);
  }

  return dur;
};

export const humanReadableDuration = (duration) => {
  const { hours, minutes, seconds } = secondsConverter(duration, 'hms');
  const zeroHours = hours === 0;
  const zeroMinutes = minutes === 0;
  if (zeroHours && zeroMinutes) return `${seconds} Sec`;

  const hoursText = zeroHours ? '' : `${hours} Hr`;
  const minText = zeroMinutes ? '' : `${minutes} Min`;
  return `${hoursText} ${minText}`.trim();
};

const getValueForDurationKey = (key, string) => {
  const regex = new RegExp(`\\d+${key}`);
  const result = string.match(regex);
  if (result) {
    return parseInt(result[0].match(/\d+/)[0]);
  }
  return 0;
};

const TRIM_REGEXP = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

export const isValidDurationString = (duration) => {
  if (!duration) {
    return false;
  }

  let durationString = duration.toString();
  durationString = durationString.replace(TRIM_REGEXP, '');

  // these two blocks ensures that it fails if the string contains characters other
  // than digits, h, m, or s
  if (durationString.match(/[\dhms]+/) === null || durationString.match(/[hms]+/) === null) {
    return false;
  }

  if (durationString.match(/[\dhms]+/)[0] !== durationString) {
    return false;
  }

  if (doMeasuresRepeat(durationString)) {
    return false;
  }

  if (!areMeasuresInOrder(durationString)) {
    return false;
  }

  return true;
};

export const padNumber = (num, length = 0) => {
  let result = `${num}`;
  while (result.length < length) {
    result = `0${result}`;
  }
  return result;
};

export const secondsConverter = (total, format) => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  const useHours = /h/.test(format);
  const useMinutes = /m/.test(format);

  if (useHours && total > 0) {
    hours += Math.floor(total / 3600);
    total %= 3600;
  }

  if (useMinutes && total > 0) {
    minutes += Math.floor(total / 60);
    total %= 60;
  }

  seconds = Math.round(total);

  if (useHours && minutes === 60) {
    hours += 1;
    minutes = 0;
  }

  if (useMinutes && seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  return {
    hours,
    minutes,
    seconds,
  };
};

export const getSecondsRemaining = (start, end) => {
  const remaining = end.getTime() - start.getTime();
  return remaining / 1000;
};

(function(Wistia) {
  var W, globalEventLoop, ref;
  W = Wistia;
  globalEventLoop = require('utilities/event_loop.js').globalEventLoop;
  if ((ref = W._initializers) != null ? ref.initEventLoop : void 0) {
    return;
  }
  W._initializers.initEventLoop = function() {
    return W.eventLoop = globalEventLoop;
  };
  return W._destructors.destroyEventLoop = function() {
    var ref1;
    if ((ref1 = W.eventLoop) != null) {
      ref1.stop();
    }
    return W.eventLoop = null;
  };
})(Wistia);

(function(W) {
  var count, elemHeight, elemWidth, merge, metricsHost, ref, ref1, sample, send;
  if (W.Metrics) {
    return;
  }
  metricsHost = require('utilities/hosts.js').metricsHost;
  ref = require('utilities/metrics.js'), send = ref.send, count = ref.count, sample = ref.sample;
  merge = require('utilities/obj.js').merge;
  ref1 = require('utilities/elem.js'), elemWidth = ref1.elemWidth, elemHeight = ref1.elemHeight;
  return W.Metrics = {
    count: count,
    sample: sample,
    send: send,
    videoCount: function(video, key, val, extraData) {
      if (val == null) {
        val = 1;
      }
      return this.videoSend(video, 'count', key, val, extraData);
    },
    videoSample: function(video, key, val, extraData) {
      return this.videoSend(video, 'sample', key, val, extraData);
    },
    videoSend: function(video, type, key, val, extraData) {
      if (extraData == null) {
        extraData = {};
      }
      return typeof video.hasData === "function" ? video.hasData((function(_this) {
        return function() {
          var bandwidth, currentAsset, ref10, ref11, ref12, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
          bandwidth = (typeof video.bandwidthTest === "function" ? video.bandwidthTest().savedResult() : void 0) || {};
          extraData = merge({
            location: ((typeof location !== "undefined" && location !== null ? (ref2 = location.protocol) != null ? ref2.length : void 0 : void 0) && (typeof location !== "undefined" && location !== null ? (ref3 = location.hostname) != null ? ref3.length : void 0 : void 0) ? location.protocol + "//" + location.hostname : null),
            agent: navigator.userAgent,
            account_key: (ref4 = video._mediaData) != null ? ref4.accountKey : void 0,
            at: video.time(),
            video_id: video.hashedId(),
            embed_type: video.playerType,
            duration: video.duration(),
            client_kbps: bandwidth.clientKbps,
            supports_hd: bandwidth.supportsHd,
            autoplay: !!((ref5 = video._opts) != null ? ref5.autoPlay : void 0),
            in_iframe: top !== self,
            device_pixel_ratio: window.devicePixelRatio,
            window_width: elemWidth(window),
            window_height: elemHeight(window),
            video_width: video.videoWidth(),
            video_height: video.videoHeight(),
            visitor_key: (ref6 = video._tracker) != null ? ref6.visitorKey() : void 0,
            event_key: (ref7 = video._tracker) != null ? ref7.eventKey() : void 0,
            event_time_delta: (ref8 = video._tracker) != null ? ref8.timeDelta() : void 0,
            connection_type: _this.connectionType(),
            version: 9,
            channel: W.channelName
          }, extraData);
          if (/romulus|html5|vulcan/.test(video.playerType)) {
            extraData.errorState = (ref9 = video.elem()) != null ? ref9.error : void 0;
          }
          if (video._cacheStatus != null) {
            extraData.cache_status = video._cacheStatus;
          }
          if (video._assetServer != null) {
            extraData.asset_server = video._assetServer;
          }
          if (video._cacheHit != null) {
            extraData.cache_hit = video._cacheHit;
          }
          if (video.inFullscreen) {
            extraData.in_fullscreen = video.inFullscreen();
          }
          if (video._substream != null) {
            extraData.substream = video._substream.index;
            extraData.adaptive = video._substream.adaptive;
            extraData.current_level = video._substream.currentLevel;
            extraData.all_level_names = video._substream.allLevelNames;
            currentAsset = video._substream.asset;
            extraData.hls_error_counts = video._substream.hlsErrorCounts();
          } else {
            extraData.substream = null;
            extraData.adaptive = null;
            currentAsset = (ref10 = video.data) != null ? ref10.asset : void 0;
          }
          if (currentAsset != null) {
            extraData.asset_type = currentAsset.type;
            extraData.asset_slug = currentAsset.slug;
            extraData.bitrate = currentAsset.bitrate;
            extraData.asset_height = currentAsset.height;
            extraData.asset_width = currentAsset.width;
          }
          if (((ref11 = video.videoElem) != null ? (ref12 = ref11.buffered) != null ? ref12.length : void 0 : void 0) > 0) {
            extraData.bufferedFrom0 = video.videoElem.buffered.end(0);
          }
          if (video._timeToFirstByte != null) {
            extraData.time_to_first_byte = video._timeToFirstByte;
          }
          extraData.buffered = video.totalBuffered != null ? video.totalBuffered() : void 0;
          extraData.played = video.totalPlayed != null ? video.totalPlayed() : void 0;
          _this.send(type, key, val, extraData);
          if (video._mediaData && video._mediaData.accountKey && video._mediaData.accountSpecificMetrics) {
            return _this.send(type, video._mediaData.accountKey + "/" + key, val, extraData);
          }
        };
      })(this)) : void 0;
    },
    countEvent: function(videoObj, eventName, statsName) {
      var base;
      statsName || (statsName = eventName);
      if ((base = this._private.countEventCallbacks)[statsName] == null) {
        base[statsName] = this._private.mkCountEventCallback(eventName, statsName);
      }
      return videoObj.rebind(eventName, this._private.countEventCallbacks[statsName]);
    },
    countEventOnce: function(videoObj, eventName, statsName) {
      var base;
      statsName || (statsName = eventName);
      if ((base = this._private.countEventOnceCallbacks)[statsName] == null) {
        base[statsName] = this._private.mkCountEventOnceCallback(eventName, statsName);
      }
      return videoObj.rebind(eventName, this._private.countEventOnceCallbacks[statsName]);
    },
    sampleEvent: function(videoObj, eventName, statsName) {
      var base;
      statsName || (statsName = eventName);
      if ((base = this._private.sampleEventCallbacks)[statsName] == null) {
        base[statsName] = this._private.mkSampleEventCallback(eventName, statsName);
      }
      return videoObj.rebind(eventName, this._private.sampleEventCallbacks[statsName]);
    },
    countShowLoadingOnce: function(videoObj) {
      var lastDuration;
      lastDuration = -1;
      return videoObj.bind('waiting', (function(_this) {
        return function(duration) {
          if (lastDuration < 2 && duration >= 2) {
            _this.videoCount(videoObj, 'player/show-loading/first', 1);
            lastDuration = duration;
            return videoObj.unbind;
          } else {
            return lastDuration = duration;
          }
        };
      })(this));
    },
    countShowLoadingAll: function(videoObj) {
      var lastDuration;
      lastDuration = -1;
      videoObj.bind('waiting', (function(_this) {
        return function(duration) {
          if (lastDuration < 2 && duration >= 2) {
            _this.videoCount(videoObj, 'player/show-loading/all', 1);
          }
          return lastDuration = duration;
        };
      })(this));
      return videoObj.bind('done-waiting', function() {
        return lastDuration = -1;
      });
    },
    countShowLoadingLongTimeOnce: function(videoObj) {
      var lastDuration;
      lastDuration = -1;
      return videoObj.bind('waiting', (function(_this) {
        return function(duration) {
          if (lastDuration < 5 && duration >= 5) {
            _this.videoCount(videoObj, 'player/show-loading-long-time/first', 1);
            lastDuration = duration;
            return videoObj.unbind;
          } else {
            return lastDuration = duration;
          }
        };
      })(this));
    },
    countShowLoadingLongTimeAll: function(videoObj) {
      var lastDuration;
      lastDuration = -1;
      videoObj.bind('waiting', (function(_this) {
        return function(duration) {
          if (lastDuration < 5 && duration >= 5) {
            _this.videoCount(videoObj, 'player/show-loading-long-time/all', 1);
          }
          return lastDuration = duration;
        };
      })(this));
      return videoObj.bind('done-waiting', function() {
        return lastDuration = -1;
      });
    },
    assetBuckets: function(video) {
      var isMobile;
      isMobile = W.detect.iphone || W.detect.android || W.detect.blackberry || /mobile/i.test(navigator.userAgent);
      if (isMobile) {
        if (W.detect.iphone) {
          return ['mobile', 'iphone'];
        } else if (W.detect.android) {
          return ['mobile', 'android'];
        } else {
          return ['mobile'];
        }
      } else {
        return [];
      }
    },
    connectionType: function() {
      var connection;
      connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
      return connection != null ? connection.type : void 0;
    },
    sumTimeRanges: function(timeRanges) {
      var i, j, ref2, sum;
      if (timeRanges == null) {
        return null;
      }
      sum = 0;
      for (i = j = 0, ref2 = timeRanges.length; 0 <= ref2 ? j < ref2 : j > ref2; i = 0 <= ref2 ? ++j : --j) {
        sum += timeRanges.end(i) - timeRanges.start(i);
      }
      return sum;
    },
    millisecondsSince: function(timeMsec) {
      return new Date().getTime() - timeMsec;
    },
    _private: {
      countEventCallbacks: {},
      mkCountEventCallback: function(eventName, statsName) {
        return function(event) {
          statsName || (statsName = eventName);
          return W.Metrics.videoCount(this, "player/" + statsName, event);
        };
      },
      countEventOnceCallbacks: {},
      mkCountEventOnceCallback: function(eventName, statsName) {
        return function(event) {
          statsName || (statsName = eventName);
          W.Metrics.videoCount(this, "player/" + statsName, event);
          return this.unbind;
        };
      },
      sampleEventCallbacks: {},
      mkSampleEventCallback: function(eventName, statsName) {
        return function(event) {
          statsName || (statsName = eventName);
          return W.Metrics.videoSample(this, "player/" + statsName, event);
        };
      }
    }
  };
})(Wistia);

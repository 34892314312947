(function(W) {
  var clearTimeouts, doTimeout, eV1Protocol, merge, proto, ref, seqId;
  eV1Protocol = require('utilities/hosts.js').eV1Protocol;
  proto = require('utilities/url.js').proto;
  ref = require('utilities/timeout-utils.js'), doTimeout = ref.doTimeout, clearTimeouts = ref.clearTimeouts;
  seqId = require('utilities/seqid.js').seqId;
  merge = require('utilities/obj.js').merge;
  W.BandwidthTest = (function() {
    function BandwidthTest(opt) {
      this.opt = opt;
      this.opt = merge({
        minKbps: 2500,
        timeoutLength: 4000,
        freshFor: 3600000
      }, this.opt);
      if (!this.opt.uuid) {
        this.opt.uuid = seqId('bandwidth_test_');
      }
    }

    BandwidthTest.prototype.start = function() {
      var endOffset, randIndex, ref1, startOffset;
      if ((ref1 = this._testXhr) != null) {
        ref1.abort();
      }
      this._testStart = new Date().getTime();
      this._testXhr = new XMLHttpRequest();
      this._testXhr.open('GET', this.testFileUrl(), true);
      randIndex = Math.floor(Math.random() * 100);
      startOffset = randIndex * 1000000;
      endOffset = startOffset + 999999;
      this._testXhr.setRequestHeader('Range', "bytes=" + startOffset + "-" + endOffset);
      doTimeout(this.uuid + ".cancel_bandwidth_test", (function(_this) {
        return function() {
          _this.trigger('timeout');
          return _this.fail();
        };
      })(this), this.opt.timeoutLength);
      this._testDataLoaded = 0;
      this._onTestXhrProgress = (function(_this) {
        return function(event) {
          _this._testDataLoaded = event.loaded;
          return _this.trigger('progress', event);
        };
      })(this);
      this._testXhr.addEventListener('progress', this._onTestXhrProgress);
      this._onTestXhrLoaded = (function(_this) {
        return function() {
          return _this.succeed();
        };
      })(this);
      this._testXhr.addEventListener('load', this._onTestXhrLoaded);
      this._testXhr.send(null);
      return this.trigger('started');
    };

    BandwidthTest.prototype.fail = function() {
      this._sampleKbps();
      this._supportsHd = false;
      this.saveResult();
      this.trigger('failed');
      return this.end();
    };

    BandwidthTest.prototype.succeed = function() {
      this._sampleKbps();
      this._supportsHd = true;
      this.saveResult();
      this.trigger('succeeded');
      this.end();
      if (this._suspiciouslyFast()) {
        return this._reportSuspiciousBandwidthTest();
      }
    };

    BandwidthTest.prototype._reportSuspiciousBandwidthTest = function() {
      var e, ref1, ref2;
      try {
        if (this._suspiciouslyFast()) {
          return W.Metrics.count('player/suspicious-bandwidth-test', 1, {
            bw_test_time: this._testTime,
            visitor_key: W.visitorKey.value(),
            location: ((typeof location !== "undefined" && location !== null ? (ref1 = location.protocol) != null ? ref1.length : void 0 : void 0) && (typeof location !== "undefined" && location !== null ? (ref2 = location.hostname) != null ? ref2.length : void 0 : void 0) ? location.protocol + "//" + location.hostname : null),
            connection_type: W.Metrics.connectionType(),
            agent: navigator.userAgent
          });
        }
      } catch (error) {
        e = error;
        return W.error(e);
      }
    };

    BandwidthTest.prototype._suspiciouslyFast = function() {
      return this._testTime < 0.05;
    };

    BandwidthTest.prototype._sampleKbps = function() {
      this._testEnd = new Date().getTime();
      this._testTime = (this._testEnd - this._testStart) / 1000;
      return this._kbps = this._testDataLoaded * 8 / 1000 / this._testTime;
    };

    BandwidthTest.prototype.endEarly = function() {
      if ((this._supportsHd == null) && (this._testDataLoaded != null)) {
        this._sampleKbps();
        this._supportsHd = this._testTime > 1 ? this._kbps >= this.opt.minKbps : true;
        this.trigger('ended-early');
        return this.end();
      } else {
        this.end();
        return this.trigger('ended-early');
      }
    };

    BandwidthTest.prototype.end = function() {
      var ref1, ref2, ref3;
      if (this._onTestXhrLoaded) {
        if ((ref1 = this._testXhr) != null) {
          ref1.removeEventListener('load', this._onTestXhrLoaded);
        }
      }
      if (this._onTestXhrProgress) {
        if ((ref2 = this._testXhr) != null) {
          ref2.removeEventListener('progress', this._onTestXhrProgress);
        }
      }
      if ((ref3 = this._testXhr) != null) {
        ref3.abort();
      }
      this._testXhr = null;
      clearTimeouts(this.uuid + ".cancel_bandwidth_test");
      return this.trigger('ended');
    };

    BandwidthTest.prototype.testFileUrl = function() {
      return this.opt.testFileUrl || (proto() === 'https:' ? 'https://embed-ssl.wistia.com/deliveries/6992339c876a95a37250fbe5b0a0eaecca3018a5/file.jpg?bust=2015-12-29a' : 'http://embed.wistia.com/deliveries/76a57ca47a1978a834d82dcf7eeb03a6.bin?bust=2015-12-29a');
    };

    BandwidthTest.prototype.saveKey = function() {
      return this.opt.saveKey || 'bandwidth_test';
    };

    BandwidthTest.prototype.saveResult = function() {
      return W.localStorage(this.saveKey(), {
        updatedAt: new Date().getTime(),
        supportsHd: this._supportsHd,
        clientKbps: this._kbps
      });
    };

    BandwidthTest.prototype.savedResult = function() {
      return W.localStorage(this.saveKey());
    };

    BandwidthTest.prototype.isFresh = function() {
      var elapsed, ref1;
      elapsed = new Date().getTime() - (((ref1 = this.savedResult()) != null ? ref1.updatedAt : void 0) || 0);
      return elapsed < this.opt.freshFor;
    };

    BandwidthTest.prototype.supportsHd = function() {
      return this._supportsHd || false;
    };

    return BandwidthTest;

  })();
  return W.mixin(W.BandwidthTest.prototype, W.bindable);
})(Wistia);

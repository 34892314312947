var slice = [].slice;

(function(Wistia) {
  var LOG_LEVELS, W, parseUrl, ref, wlog;
  W = Wistia;
  if (W.wlog) {
    return;
  }
  ref = require('utilities/wlog.js'), wlog = ref.wlog, LOG_LEVELS = ref.LOG_LEVELS;
  parseUrl = require('utilities/url.js').parseUrl;
  W.wlog = wlog;
  W.log = wlog.error;
  W.error = wlog.error;
  W.warn = wlog.warn;
  W.notice = wlog.notice;
  W.info = wlog.info;
  W.debug = wlog.debug;
  W.logLevels = LOG_LEVELS;
  W.stacktrace = function() {
    var e;
    try {
      throw new Error('stacktrace');
    } catch (error) {
      e = error;
      return e.stack;
    }
  };
  W.logHelpers = {
    _log: function() {
      var logFn, messages, type;
      type = arguments[0], messages = 2 <= arguments.length ? slice.call(arguments, 1) : [];
      logFn = W[type];
      messages = [].concat(this._logPrefix()).concat(messages);
      return logFn.apply(null, messages);
    },
    _logPrefix: function() {
      return [this.constructor.name];
    },
    error: function() {
      var messages;
      messages = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return this._log.apply(this, ['error'].concat(slice.call(messages)));
    },
    warn: function() {
      var messages;
      messages = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return this._log.apply(this, ['notice'].concat(slice.call(messages)));
    },
    notice: function() {
      var messages;
      messages = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return this._log.apply(this, ['notice'].concat(slice.call(messages)));
    },
    info: function() {
      var messages;
      messages = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return this._log.apply(this, ['info'].concat(slice.call(messages)));
    },
    debug: function() {
      var messages;
      messages = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return this._log.apply(this, ['debug'].concat(slice.call(messages)));
    },
    log: function() {
      var messages;
      messages = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      return this._log.apply(this, ['error'].concat(slice.call(messages)));
    }
  };
  W._initializers.initWLog = function() {
    var level, pageUrl, ref1, ref2, ref3, ref4, ref5, ref6, referrerUrl, wgrep, wgrepv;
    pageUrl = parseUrl(location.href);
    if (document.referrer) {
      referrerUrl = parseUrl(document.referrer);
    }
    if ((level = pageUrl != null ? (ref1 = pageUrl.params) != null ? ref1.wlog : void 0 : void 0) || (top !== self && (level = referrerUrl != null ? (ref2 = referrerUrl.params) != null ? ref2.wlog : void 0 : void 0))) {
      wlog.setLevel(level);
    }
    if ((wgrep = pageUrl != null ? (ref3 = pageUrl.params) != null ? ref3.wgrep : void 0 : void 0) || (wgrep = referrerUrl != null ? (ref4 = referrerUrl.params) != null ? ref4.wgrep : void 0 : void 0)) {
      wlog.setGrep(new RegExp(wgrep, 'i'));
    }
    if ((wgrepv = pageUrl != null ? (ref5 = pageUrl.params) != null ? ref5.wgrepv : void 0 : void 0) || (wgrepv = referrerUrl != null ? (ref6 = referrerUrl.params) != null ? ref6.wgrepv : void 0 : void 0)) {
      return wlog.setGrepv(new RegExp(wgrepv, 'i'));
    }
  };
  return W._destructors.destroyWLog = function() {
    return wlog != null ? wlog.reset() : void 0;
  };
})(Wistia);

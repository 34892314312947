export class PlayerBehavior {
  constructor(impl) {
    this.impl = impl;
    this.unbinds = [];
    this.namedUnbinds = {};
  }

  addNamedUnbind(name, unbind) {
    // guard against double-binding
    if (this.namedUnbinds[name]) {
      this.namedUnbinds[name]();
    }
    this.namedUnbinds[name] = unbind;
  }

  removeNamedUnbind(name) {
    if (this.namedUnbinds[name]) {
      this.namedUnbinds[name]();
      delete this.namedUnbinds[name];
    }
  }

  destroy() {
    if (this.unbinds instanceof Array) {
      this.unbinds.map((unbind) => unbind());
    }
    for (let name in this.namedUnbinds) {
      if (this.namedUnbinds.hasOwnProperty(name)) {
        this.namedUnbinds[name].map((unbind) => unbind());
      }
    }
    this.unbinds = [];
    this.namedUnbinds = {};
    this.impl = null;
    this._destroyed = true;
  }
}
